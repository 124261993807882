import { Injectable } from '@angular/core';
import { AuthToken } from '../models/auth-token.model';

const tokenKey: string = 'token';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  static saveAuthToken(token: AuthToken) {
    localStorage.setItem(tokenKey, JSON.stringify(token));
  }

  static getAuthToken(): AuthToken | null {
    const authToken = localStorage.getItem(tokenKey);
    return authToken ? JSON.parse(authToken) : null;
  }

  static removeAuthToken() {
    localStorage.removeItem(tokenKey);
  }
}

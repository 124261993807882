import { Component, Input } from '@angular/core';
import { AppIcon } from 'src/app/enums/icons.enum';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Input()
  searchKey: string = 'search';

  AppIcon = AppIcon;
  value: string = '';

  constructor(private searchService: SearchService) {}

  valueChanged(value: string) {
    this.searchService.getUserInputSubject(this.searchKey)?.next(value);
  }

  delete() {
    this.value = '';
    this.valueChanged(this.value);
  }
}

import { ConsultantStatus } from '../enums/consultant-status.enum';
import { ImageFile } from './image-file.model';

export class ConsultantModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  active: boolean;
  profilePicture: ImageFile;
  profilePictureUrl: string;
  lastLoginAt: string;
  createdAt: string;
  constructor(consultantObj: any) {
    this.id = consultantObj?.id ? consultantObj.id : undefined;
    this.firstName = consultantObj?.firstName ? consultantObj.firstName : undefined;
    this.lastName = consultantObj?.lastName ? consultantObj.lastName : undefined;
    this.email = consultantObj?.email ? consultantObj.email : undefined;
    this.phone = consultantObj?.phone ? consultantObj.phone : undefined;
    this.active =
      consultantObj?.active !== null && consultantObj?.active !== undefined ? consultantObj.active : undefined;
    this.profilePicture = consultantObj && consultantObj.profilePicture ? consultantObj.profilePicture : undefined;
    this.profilePictureUrl =
      consultantObj && consultantObj.profilePictureUrl ? consultantObj.profilePictureUrl : undefined;
    this.lastLoginAt = consultantObj?.lastLoginAt ? consultantObj.lastLoginAt : undefined;
    this.createdAt = consultantObj?.createdAt ? consultantObj.createdAt : undefined;
  }

  get initials(): string {
    return (this.firstName?.charAt(0) || '').toUpperCase() + (this.lastName?.charAt(0) || '')?.toUpperCase();
  }

  get status(): ConsultantStatus {
    if (!this.lastLoginAt) return ConsultantStatus.NeverLoggedIn;
    if (!this.active) return ConsultantStatus.Inactive;
    return ConsultantStatus.Active;
  }
}

<div class="icon-circle-container {{ size }} {{ theme }}">
  <img *ngIf="finalImageUrl()" [src]="finalImageUrl()" />
  <div *ngIf="displayInitials()">{{ initials }}</div>
  <img *ngIf="displayLocalImage()" [src]="imageFile?.cropped?.dataURL" />
</div>

<div *ngIf="isEditMode" class="edit-buttons">
  <app-circular-icon-button [icon]="AppIcon.IconEdit" [type]="'button'" (click)="fileUpload.click()" />
  <app-circular-icon-button [icon]="AppIcon.IconDelete" [type]="'button'" (click)="onDelete()" />
  <input
    type="file"
    onclick="value = null"
    [accept]="'image/*'"
    (change)="cropper.selectInputEvent($event)"
    #fileUpload
  />
</div>

<div class="cropper-container">
  <ly-img-cropper
    class="{{ size }}"
    [ngClass]="{ hide: !isEditMode }"
    [config]="cropConfig"
    (cleaned)="onCleaned()"
    (cropped)="onCropped($event)"
    (loaded)="onLoaded($event)"
    (error)="onError($event)"
  />
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppIcon } from '../enums/icons.enum';
import { InputFormat } from '../enums/input-format.enum';
import { DropdownType } from '../enums/dropdown-type.enum';
import { TaskStatus } from '../enums/task-status.enum';
import { Tab } from '../models/tab.model';
import { TabType } from '../enums/tab-type.enum';
import { DatepickerType } from '../enums/datepicker-type.enum';
import { RangeDates } from '../components/datepicker/datepicker.component';
import { ModalService } from '../services/modal.service';
import { SandboxModalComponent } from './sandbox-modal/sandbox-modal.component';
import { Subscription } from 'rxjs';
import { DropdownOption } from '../components/dropdown/dropdown.component';
import { DropdownService } from '../services/dropdown.service';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss'],
})
export class SandboxComponent implements OnInit, OnDestroy {
  private subs$: Subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit(): void {
    this.optionKey = this.options[0].key;
    this.optionStatusKey = this.optionTaskStatus[0].key;
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  InputFormat = InputFormat;
  AppIcon = AppIcon;
  DropdownType = DropdownType;
  DatepickerType = DatepickerType;
  TabType = TabType;

  optionKey!: string;
  optionStatusKey!: TaskStatus;

  tabs = [new Tab('button-tab-1'), new Tab('button-tab-2'), new Tab('button-tab-3', true)];

  get options(): DropdownOption[] {
    return this.dropdownService.countries;
  }

  get optionTaskStatus(): DropdownOption[] {
    return this.dropdownService.taskStatus;
  }

  submitTestForm(form: NgForm) {
    console.log(form);
  }

  selectedTab(tab: Tab): void {
    console.log(tab);
  }

  onDateChange(date: Date | null) {
    console.log(date);
  }

  onRangeDatesChange(rangeDates: RangeDates) {
    console.log(rangeDates.startDate);
    console.log(rangeDates.endDate);
  }

  openDialog() {
    this.subs$.add(
      this.modalService
        .open<SandboxModalComponent>(SandboxModalComponent, { panelClass: 'modal-form' })
        .subscribe((result) => {
          console.log('modal result is: ' + result);
        }),
    );
  }
}

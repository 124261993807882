import { Component } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from './enums/langs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  displaySidebar!: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.setAppLanguage();
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.displaySidebar = !data.url.includes('auth') && !data.url.includes('sandbox');
      }
    });
    if (this.authService.isAuthenticated()) {
      this.authService.fetchCurrentAccount();
    }
  }

  private setAppLanguage() {
    this.translate.addLangs(Object.values(AppLang));
    this.translate.setDefaultLang(AppLang.DEFAULT_LANG);
    this.translate.use(AppLang.DEFAULT_LANG);
  }
}

import { Directive, ElementRef, HostBinding, Input, OnChanges, OnDestroy, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appHighlight]',
})
export class HighlightDirective implements OnChanges, OnDestroy {
  @Input('appHighlight') searchTerm!: string;
  @Input() caseSensitive = false;
  @Input() customClasses = '';

  @HostBinding('innerHtml') content!: string | null;

  private text: string | null = null;
  private observer = new MutationObserver(() => {
    if (this.text != this.el.nativeElement.textContent) {
      this.highlightText();
    }
  });

  constructor(
    private el: ElementRef,
    private sanitizer: DomSanitizer,
  ) {
    this.registerListenerForDomChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('searchTerm' in changes || 'caseSensitive' in changes) {
      this.highlightText();
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private highlightText() {
    if (this.el?.nativeElement) {
      this.text = (this.el.nativeElement as HTMLElement).textContent;
      if (this.text) {
        if (this.searchTerm === '') {
          this.content = this.text;
        } else {
          const regex = new RegExp(this.searchTerm, this.caseSensitive ? 'g' : 'gi');
          const newText: string = this.text.replace(regex, (match: string) => {
            return `<mark class="highlight ${this.customClasses}">${match}</mark>`;
          });
          const sanitzed = this.sanitizer.sanitize(SecurityContext.HTML, newText);
          this.content = sanitzed;
        }
      }
    }
  }

  private registerListenerForDomChanges() {
    this.observer.observe(this.el.nativeElement, {
      attributes: false,
      childList: true,
      subtree: true
    });
  }
}

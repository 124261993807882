import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, map, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../networking/local-storage.service';
import { AuthToken } from '../models/auth-token.model';
import { ResponseModel, SUCCESS } from '../models/response.model';
import { RoutingService } from './routing.service';
import { environment } from '../../environments/environment';
import { AccountService } from './account.service';
import { RequestParams } from '../utils/RequestParams';
import { AccountModel } from '../models/account.model';
import { ResponseError } from '../models/response-error.model';

export const AuthGuard: CanMatchFn = () => {
  if (inject(AuthService).isAuthenticated()) return true;
  return inject(Router).createUrlTree(['/auth/login']);
};

export const LoginGuard: CanMatchFn = () => {
  if (!inject(AuthService).isAuthenticated()) return true;
  return inject(Router).createUrlTree(['tasks']);
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly loginUrl: string = `${environment.host}auth/login`;
  readonly refreshTokenUrl: string = `${environment.host}auth/refresh-token`;
  readonly logoutUrl: string = `${environment.host}auth/logout`;
  readonly forgotPasswordUrl: string = `${environment.host}auth/forgot-password`;
  readonly resetPasswordUrl: string = `${environment.host}auth/reset-password`;
  readonly changePasswordUrl: string = `${environment.host}auth/change-password`;

  logoutEvent$: Subject<void> = new Subject();
  currentAccount$: BehaviorSubject<AccountModel | null> = new BehaviorSubject<AccountModel | null>(null);

  constructor(
    private http: HttpClient,
    private routingService: RoutingService,
    private accountService: AccountService,
  ) {}

  isAuthenticated(): boolean {
    const authToken: AuthToken | null = LocalStorageService.getAuthToken();
    return !!(authToken && authToken.accessToken);
  }

  private parseAuthResponse(response: ResponseModel<AuthToken>) {
    if (response.message === SUCCESS) {
      const authToken: AuthToken = new AuthToken(response.data.accessToken, response.data.refreshToken);
      LocalStorageService.saveAuthToken(authToken);
      this.accountService.getMyAccount(new RequestParams({})).subscribe((currentAccount) => {
        this.currentAccount$.next(currentAccount);
      });
    }
  }

  authenticate(url: string, body: Object): Observable<any> {
    return this.http.post<ResponseModel<AuthToken>>(url, body).pipe(
      map((response: ResponseModel<AuthToken>): void => {
        this.parseAuthResponse(response);
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  forgotPassword(email: string) {
    return this.http
      .post<ResponseModel<void>>(this.forgotPasswordUrl, {
        email: email,
      })
      .pipe(
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  resetPassword(passwordResetToken: string, newPassword: string) {
    return this.http
      .post<ResponseModel<AuthToken>>(this.resetPasswordUrl, {
        resetToken: passwordResetToken,
        newPassword: newPassword,
      })
      .pipe(
        map((response: ResponseModel<AuthToken>): void => {
          this.parseAuthResponse(response);
        }),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  login(email: string, password: string): Observable<any> {
    return this.authenticate(this.loginUrl, {
      email: email,
      password: password,
    });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http
      .post<ResponseModel<void>>(this.changePasswordUrl, {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      .pipe(
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  fetchCurrentAccount() {
    this.accountService.getMyAccount(new RequestParams({})).subscribe((currentAccount) => {
      this.currentAccount$.next(currentAccount);
    });
  }

  onCurrentAccountUpdated(currentAccount: AccountModel) {
    this.currentAccount$.next(currentAccount);
  }

  refreshToken(): Observable<any> {
    const authToken: AuthToken | null = LocalStorageService.getAuthToken();
    if (authToken && authToken.accessToken) {
      return this.authenticate(this.refreshTokenUrl, {
        refreshToken: authToken.refreshToken,
      });
    }
    return EMPTY;
  }

  logout(): void {
    const authToken: AuthToken | null = LocalStorageService.getAuthToken();
    if (authToken && authToken.accessToken) {
      this.http
        .post<ResponseModel<void>>(this.logoutUrl, {})
        .pipe(
          map((response: ResponseModel<void>): void => {
            if (response.message === SUCCESS) {
              this.clearDataAndGoToLogin();
              this.logoutEvent$.next();
              this.currentAccount$.next(null);
            }
          }),
          catchError((error) => {
            throw new ResponseError(error);
          }),
        )
        .subscribe();
    } else {
      this.routingService.navigate('/login');
    }
  }

  clearDataAndGoToLogin() {
    LocalStorageService.removeAuthToken();
    this.routingService.navigate('/login');
  }
}

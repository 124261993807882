import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './clickOutside.directive';
import { HighlightDirective } from './highlight.directive';
import { PhoneDirective } from './phone.directive';
import { LoadingDirective } from './loading.directive';

@NgModule({
  declarations: [ClickOutsideDirective, HighlightDirective, PhoneDirective, LoadingDirective],
  exports: [ClickOutsideDirective, HighlightDirective, PhoneDirective, LoadingDirective],
  imports: [CommonModule],
})
export class DirectivesModule {}

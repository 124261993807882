export class ResponseModel<DataModel> {
  message!: string;
  code!: string;
  data!: DataModel;

  constructor(errorObj: any) {
    this.code = errorObj?.error?.code;
    this.message = errorObj?.error?.message;
    this.data = errorObj?.error?.data;
  }
}

export const SUCCESS: string = 'success';

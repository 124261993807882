import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { AppIcon } from '../../enums/icons.enum';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() isChecked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() labelSize: 'default' | 'large' = 'default';
  @Output() onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly AppIcon = AppIcon;

  onChange: (select: boolean) => void = () => {};

  writeValue(value: boolean): void {
    this.isChecked = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  toggleCheckbox(): void {
    this.isChecked = !this.isChecked;
    this.onCheck.emit(this.isChecked);
    this.onChange(this.isChecked);
  }
}

<div class="input-container">
  <input
    type="text"
    placeholder="{{ 'component.search' | translate }}"
    [(ngModel)]="value"
    (ngModelChange)="valueChanged($event)"
  />
  <app-svg-icon class="blue" [src]="value ? AppIcon.IconX : AppIcon.IconSearch" (click)="value ? delete() : null">
  </app-svg-icon>
  <div class="bg-effect"></div>
</div>

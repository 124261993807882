<div class="card-container">
  <div class="top-container">
    <div>
      <ng-content select="[details]" />
    </div>
    <div class="right-content">
      <ng-content select="[label]" />
      <div class="actions-container">
        <ng-content select="[actions]" />
      </div>
    </div>
  </div>
  <div class="bottom-container">
    <ng-content select="[bottom-content]" />
  </div>
</div>

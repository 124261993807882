import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tab } from '../../models/tab.model';
import { TabType } from '../../enums/tab-type.enum';
import { AppIcon } from 'src/app/enums/icons.enum';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnChanges {
  @Input() tabs!: Tab[];
  @Input() selectedTab!: Tab;
  @Input() type: TabType = TabType.Underlined;
  @Input() readonly: boolean = false;
  @Output() tabSelected: EventEmitter<Tab> = new EventEmitter<Tab>();

  readonly TabType = TabType;
  readonly AppIcon = AppIcon;
  selectedIndex!: number;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectTab(this.selectedTab ?? this.tabs[0]);
  }

  selectTab(tab: Tab): void {
    this.selectedTab = tab;
    this.selectedIndex = this.tabs.indexOf(tab);
    this.tabSelected.emit(tab);
  }
}

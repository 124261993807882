export enum AppIcon {
  ArrowDown = 'assets/icons/icon-arrow-down.svg',
  ArrowRight = 'assets/icons/icon-arrow-right.svg',
  ArrowLeft = 'assets/icons/icon-arrow-left.svg',
  IconCalendar = 'assets/icons/icon-calendar.svg',
  IconCheck = 'assets/icons/icon-check.svg',
  IconCheckLarge = 'assets/icons/icon-check-large.svg',
  IconCheckLargeSuccess = 'assets/icons/icon-check-large-success.svg',
  IconConsultants = 'assets/icons/icon-consultants.svg',
  IconDelete = 'assets/icons/icon-delete.svg',
  IconDocument = 'assets/icons/icon-document.svg',
  IconDots = 'assets/icons/icon-dots.svg',
  IconDownload = 'assets/icons/icon-download.svg',
  IconEdit = 'assets/icons/icon-edit.svg',
  IconError = 'assets/icons/icon-error.svg',
  IconHide = 'assets/icons/icon-hide.svg',
  IconPlus = 'assets/icons/icon-plus.svg',
  IconProjects = 'assets/icons/icon-projects.svg',
  IconReports = 'assets/icons/icon-reports.svg',
  IconSearch = 'assets/icons/icon-search.svg',
  IconShow = 'assets/icons/icon-show.svg',
  IconStar = 'assets/icons/icon-star.svg',
  IconSuccess = 'assets/icons/icon-success.svg',
  IconTasks = 'assets/icons/icon-tasks.svg',
  IconUpload = 'assets/icons/icon-upload.svg',
  IconUserManagement = 'assets/icons/icon-user-management.svg',
  IconWarning = 'assets/icons/icon-warning.svg',
  IconX = 'assets/icons/icon-x.svg',
  IconQuestions = 'assets/icons/icon-questions.svg',

  LogoBig = 'assets/icons/logo-big.svg',
  LogoSmall = 'assets/icons/logo-small.svg',
}

import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { AppIcon } from 'src/app/enums/icons.enum';

@Component({
  selector: 'app-selectable-icon-button',
  templateUrl: './selectable-icon-button.component.html',
  styleUrls: ['./selectable-icon-button.component.scss'],
})
export class SelectableIconButtonComponent implements ControlValueAccessor {
  @Input() icon!: AppIcon;
  @Input() selected!: boolean;

  onChange: (select: boolean) => void = () => {};

  toggle() {
    this.selected = !this.selected;
    this.onChange(this.selected);
  }

  writeValue(value: boolean): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}

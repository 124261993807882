<form #testForm="ngForm" (ngSubmit)="submit(testForm)">
  <app-input-text
    [labelName]="'Email address'"
    [labelTheme]="'dark'"
    [type]="'email'"
    [error]="testForm.submitted && !emailField.valid ? 'invalidFormatError' : ''"
    name="email"
    #emailField="ngModel"
    ngModel
    required
    email
  >
  </app-input-text>
  <app-input-text
    [labelName]="'Password'"
    [labelTheme]="'dark'"
    [format]="InputFormat.Password"
    [type]="'text'"
    [error]="testForm.submitted && !passwordField.valid ? 'requiredError' : ''"
    name="password"
    #passwordField="ngModel"
    ngModel
    required
  >
  </app-input-text>
  <div class="button-container">
    <app-button [type]="'submit'">Ok</app-button>
    <app-button (click)="close()">Close</app-button>
  </div>
</form>

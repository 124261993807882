import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppIcon } from 'src/app/enums/icons.enum';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileLoaderComponent),
      multi: true,
    },
  ],
})
export class FileLoaderComponent implements ControlValueAccessor {
  @Input() disabled!: boolean;
  @Input() error: boolean = false;
  @Output()
  onSelectedFileChanged: EventEmitter<File | null> = new EventEmitter<File | null>();

  AppIcon = AppIcon;
  file: File | null = null;

  get fileName(): string {
    return this.file ? this.file.name : '';
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    this.onChange(this.file);
    this.onSelectedFileChanged.emit(this.file);
  }

  onFileRemoved() {
    this.file = null;
    this.onChange(this.file);
    this.onSelectedFileChanged.emit(null);
  }

  onChange = (file: File | null) => {};

  writeValue(file: File): void {
    this.file = file;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: string = 'button';
  @Input() disabled!: boolean;
  @Input() fullSize!: boolean;
  @Input() theme: 'default' | 'grey' | 'white' = 'default';
}

<div class="sidebar-container">
  <div class="logo-container">
    <!-- TODO replace with logo -->
    <app-svg-icon [src]="AppIcon.LogoSmall" />
  </div>

  <div class="navigation-container" *ngFor="let item of appSidebarPages">
    <a class="navigation-row" [routerLink]="['/', item.key]" routerLinkActive="active">
      <app-svg-icon [src]="item.icon" />
      {{ item.title | translate }}
    </a>
  </div>

  <div class="profile-cell-container">
    <div class="profile-cell-background"></div>
    <app-icon-circle [initials]="account?.initials || '-'" [imageUrl]="account?.profilePictureUrl || ''" />
    <div>
      <div class="profile-name">{{ account?.firstName }} {{ account?.lastName }}</div>
      <div class="profile-role">
        {{ 'account.role.' + account?.role | translate }}
      </div>
    </div>
    <div class="button-container" (clickOutside)="onClickOutside()">
      <app-icon-button [icon]="AppIcon.IconDots" [fill]="'white'" (click)="toggleAccountMenu()" />
      <app-account-menu *ngIf="isAccountMenuOpen" (closeMenu)="toggleAccountMenu()" />
    </div>
  </div>
</div>

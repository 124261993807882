export enum DatepickerType {
  Default,
  Range,
}

export enum DateType {
  Default,
  Start,
  End,
}

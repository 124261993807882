import { Component, Input } from '@angular/core';
import { AppIcon } from 'src/app/enums/icons.enum';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent {
  @Input() textTheme: 'light' | 'dark' = 'light';
  @Input() disabled!: boolean;
  @Input() prefixIcon!: AppIcon;
  @Input() suffixIcon!: AppIcon;
}

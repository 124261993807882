<label [ngClass]="{ 'disabled-state': disabled }">
  <div *ngIf="labelName" class="label-container" [ngClass]="labelTheme">
    {{ labelName | translate }}
  </div>
  <div
    class="input-container {{ inputTheme }}"
    [ngClass]="{ 'error-state': error, 'disabled-state': disabled }"
    *ngIf="isEditMode; else isViewMode"
  >
    <textarea
      [(ngModel)]="value"
      [ngClass]="{ disabled: disabled }"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
      (ngModelChange)="onInput($event)"
    ></textarea>
    <div class="bg-effect"></div>
  </div>
  <ng-template #isViewMode>
    <div class="{{ valueTheme }}">{{ value }}</div>
  </ng-template>
</label>

<div *ngIf="error" class="error-message">{{ error | translate }}</div>

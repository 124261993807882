import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppIcon } from 'src/app/enums/icons.enum';
import { DocumentModel } from 'src/app/models/document.model';
import { ClientsService } from 'src/app/services/clients.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent {
  readonly AppIcon = AppIcon;

  @Input()
  name?: string = '-';

  @Input()
  url?: string = '';

  @Input()
  document?: DocumentModel;

  @Output()
  onDocumentDeleted: EventEmitter<string> = new EventEmitter<string>();

  clientId: string = '';

  constructor(
    private modalService: ModalService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      this.clientId = params['id'];
    });
  }

  get documentName() {
    if (this.document) {
      return this.document.name || this.name || '';
    }
    return this.name || '';
  }

  get documentUrl() {
    if (this.document) {
      return this.document.url || this.url || '';
    }
    return this.url || '';
  }

  get documentId() {
    if (this.document) {
      return this.document.id || '';
    }
    return '';
  }

  view() {
    if (this.documentUrl) {
      window.open(this.documentUrl, '_blank');
    }
  }

  async download() {
    if (this.documentUrl) {
      const resp = await fetch(this.documentUrl);
      const blob = await resp.blob();
      const documentURL = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = this.documentName;
      anchor.href = documentURL;
      anchor.rel = 'noopener noreferrer';
      anchor.target = '_blank';

      anchor.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
    }
  }

  delete() {
    this.modalService
      .openWarning({ title: 'clients.details.deleteDocumentWarningAlert', positiveButtonText: 'button.delete' })
      .subscribe((confirm) => {
        if (confirm) {
          this.clientsService.deleteFile(this.clientId, this.documentId).subscribe({
            next: () => {
              this.onDocumentDeleted.emit(this.documentId);
              this.modalService.openSuccess({ subtitle: 'clients.details.deleteDocumentSuccessAlert' });
            },
            error: () => {
              this.modalService.openError({ subtitle: 'clients.details.deleteDocumentErrorAlert' });
            },
          });
        }
      });
  }
}

export class ImageFile {
  original: any;
  cropped: any;
  url?: string;

  constructor(original: any, cropped: any, url?: string) {
    this.original = original;
    this.cropped = cropped;
    this.url = url;
  }
}

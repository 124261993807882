import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SandboxComponent } from './sandbox/sandbox.component';
import { AuthGuard, LoginGuard } from './services/auth.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canMatch: [LoginGuard],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./tasks/tasks.module').then((m) => m.TasksModule),
    canMatch: [AuthGuard],
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    canMatch: [AuthGuard],
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule),
    canMatch: [AuthGuard],
  },
  {
    path: 'consultants',
    loadChildren: () => import('./consultants/consultants.module').then((m) => m.ConsultantsModule),
    canMatch: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canMatch: [AuthGuard],
  },
  {
    path: 'questions',
    loadChildren: () => import('./questions/questions.module').then((m) => m.QuestionsModule),
    canMatch: [AuthGuard],
  },
  // TODO remove after implementation is done
  {
    path: 'sandbox',
    component: SandboxComponent,
  },
  {
    path: '',
    redirectTo: '/tasks',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/tasks',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor {
  @Input() labelName!: string;
  @Input() labelTheme!: 'light' | 'dark';
  @Input() inputTheme: 'light' | 'dark' = 'light';
  @Input() valueTheme: 'normal' | 'bold' = 'bold';
  @Input() disabled: boolean = false;
  @Input() error!: string;
  @Input() placeholder: string = '';
  @Input() isEditMode: boolean = true;

  value: any;
  isTouched: boolean = false;
  isDisabled: boolean = false;

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInput(value: string) {
    this.markAsTouched();
    if (!this.isDisabled) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.onTouched();
      this.isTouched = true;
    }
  }
}

import { catchError, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../models/response.model';
import { environment } from '../../environments/environment';
import { RequestParams } from '../utils/RequestParams';
import { CreateUpdateProjectModel, ProjectModel } from '../models/project.model';
import { Injectable } from '@angular/core';
import { ResponseError } from '../models/response-error.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  readonly projectsUrl: string = `${environment.host}projects`;

  constructor(private http: HttpClient) {}

  getProjects(params: RequestParams): Observable<ProjectModel[]> {
    return this.http.get<ResponseModel<ProjectModel[]>>(this.projectsUrl, { params: params.getParamsObject() }).pipe(
      map((response: ResponseModel<ProjectModel[]>) => {
        return response.data.map((project: ProjectModel) => new ProjectModel(project));
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  getProject(id: string, params: RequestParams): Observable<ProjectModel> {
    return this.http
      .get<ResponseModel<ProjectModel>>(`${this.projectsUrl}/${id}`, { params: params.getParamsObject() })
      .pipe(
        map((response: ResponseModel<ProjectModel>) => new ProjectModel(response.data)),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  createProject(project: Partial<CreateUpdateProjectModel>, params: RequestParams): Observable<ProjectModel> {
    return this.http
      .post<ResponseModel<ProjectModel>>(`${this.projectsUrl}`, project, { params: params.getParamsObject() })
      .pipe(
        map((response: ResponseModel<ProjectModel>) => new ProjectModel(response.data)),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  updateProject(
    projectId: string,
    project: Partial<CreateUpdateProjectModel>,
    params: RequestParams,
  ): Observable<ProjectModel> {
    return this.http
      .patch<ResponseModel<ProjectModel>>(`${this.projectsUrl}/${projectId}`, project, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: ResponseModel<ProjectModel>) => new ProjectModel(response.data)),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  deleteProject(id: string): Observable<any> {
    return this.http.delete<ResponseModel<void>>(`${this.projectsUrl}/${id}`).pipe(
      map((response: ResponseModel<void>) => response),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }
}

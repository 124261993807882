<app-button (click)="openDialog()">Open dialog example</app-button>
<div class="app-datepicker">
  <h1>Custom Datepicker</h1>
  <app-datepicker labelName="Label name" (dateChanged)="onDateChange($event)" />
  <div style="margin-top: 20px"></div>
  <app-datepicker [disabled]="true" labelName="Label name (disabled)" />
  <h3 style="margin-top: 20px">Range (filter)</h3>
  <app-datepicker [type]="DatepickerType.Range" (rangeDatesChanged)="onRangeDatesChange($event)" />
</div>

<div class="app-input-text-wrapper">
  <h1>Custom input text component</h1>
  <form #testForm="ngForm" (ngSubmit)="submitTestForm(testForm)">
    <div class="top-content">
      <app-input-text
        [labelName]="'Email address'"
        [labelTheme]="'dark'"
        [type]="'email'"
        [error]="testForm.submitted && !emailField.valid ? 'invalidFormatError' : ''"
        name="email"
        #emailField="ngModel"
        ngModel
        required
        email
      >
      </app-input-text>
      <app-input-text
        [labelName]="'Password'"
        [labelTheme]="'dark'"
        [format]="InputFormat.Password"
        [type]="'text'"
        [error]="testForm.submitted && !passwordField.valid ? 'requiredError' : ''"
        name="password"
        #passwordField="ngModel"
        ngModel
        required
      >
      </app-input-text>
      <app-input-text
        [labelName]="'Disabled password'"
        [labelTheme]="'dark'"
        [format]="InputFormat.Password"
        [type]="'text'"
        [disabled]="true"
        name="password"
        #disabledPasswordField="ngModel"
        ngModel
        required
      >
      </app-input-text>

      <app-search-bar></app-search-bar>

      <app-highlight-text [text]="'Highlight text component'"/>
      <h1>
        <app-highlight-text [text]="'Use the above search bar to highlight the text.'"/>
      </h1>
    </div>

    <div class="file-loader">
      <app-file-loader
        [error]="testForm.submitted && !fileUpload.valid"
        name="fileUpload"
        #fileUpload="ngModel"
        ngModel
        required
      ></app-file-loader>
      <app-file-loader [disabled]="true"></app-file-loader>
    </div>

    <div class="bottom-content">
      <div class="input-container">
        <app-input-text [labelName]="'Label name'" [labelTheme]="'light'" [type]="'number'" name="other" ngModel>
        </app-input-text>
        <app-input-text
          [labelName]="'Disabled field'"
          [labelTheme]="'light'"
          [type]="'text'"
          [disabled]="true"
          name="disabled"
          ngModel
        >
        </app-input-text>
      </div>
    </div>
    <div class="button-container">
      <app-button [type]="'submit'" [fullSize]="true">Submit form</app-button>
    </div>
  </form>
</div>

<div class="app-buttons">
  <h1>Custom buttons</h1>
  <h2>app-button</h2>

  <div class="default-button">
    <h3>Default</h3>
    <app-button>button-action-DEFAULT</app-button>
    <app-button [disabled]="true">button-action-INACTIVE</app-button>
    <app-button [fullSize]="true">button-action-big-DEFAULT</app-button>
    <app-button [fullSize]="true" [disabled]="true"> button-action-big-INACTIVE </app-button>
  </div>

  <div class="grey-button">
    <h3>Grey</h3>
    <app-button [theme]="'grey'">button-grey-bg-DEFAULT</app-button>
    <app-button [theme]="'grey'" [disabled]="true">button-grey-bg-INACTIVE </app-button>
  </div>
  <div class="white-button">
    <h3>White</h3>
    <app-button [theme]="'white'">button-white-bg-DEFAULT</app-button>
    <app-button [theme]="'white'" [disabled]="true">button-white-bg-INACTIVE </app-button>
  </div>

  <h2>app-text-button</h2>
  <div class="text-button-container">
    <app-text-button>button-text-blue-DEFAULT</app-text-button>
    <app-text-button [disabled]="true">button-text-blue-INACTIVE</app-text-button>
    <app-text-button [textTheme]="'dark'">button-text-dark-blue-DEFAULT</app-text-button>
    <app-text-button [textTheme]="'dark'" [disabled]="true">button-text-dark-blue-INACTIVE</app-text-button>
  </div>

  <h2>app-text-button with prefix icon</h2>
  <div class="text-button-container">
    <app-text-button [prefixIcon]="AppIcon.IconPlus">button-text-blue-DEFAULT</app-text-button>
    <app-text-button [prefixIcon]="AppIcon.IconPlus" [disabled]="true">button-text-blue-INACTIVE</app-text-button>
    <app-text-button [prefixIcon]="AppIcon.ArrowLeft">button-text-blue-DEFAULT</app-text-button>
    <app-text-button [prefixIcon]="AppIcon.ArrowLeft" [disabled]="true">button-text-blue-INACTIVE</app-text-button>
  </div>

  <h2>app-text-button with suffix icon</h2>
  <div class="text-button-container">
    <app-text-button [suffixIcon]="AppIcon.ArrowRight">button-text-blue-DEFAULT</app-text-button>
    <app-text-button [suffixIcon]="AppIcon.ArrowRight" [disabled]="true">button-text-blue-INACTIVE</app-text-button>
  </div>

  <h2>app-icon-button</h2>
  <div class="icon-button-container">
    <app-icon-button [icon]="AppIcon.IconHide"></app-icon-button>
    <app-icon-button [icon]="AppIcon.IconShow"></app-icon-button>
    <app-icon-button [icon]="AppIcon.IconDownload"></app-icon-button>
    <app-icon-button [icon]="AppIcon.IconEdit"></app-icon-button>
    <app-icon-button [icon]="AppIcon.IconX"></app-icon-button>
    <app-icon-button [icon]="AppIcon.ArrowDown"></app-icon-button>
    <app-icon-button [disabled]="true" [icon]="AppIcon.IconDelete"></app-icon-button>
  </div>

  <h2>app-circular-icon-button</h2>
  <div class="icon-button-container">
    <app-circular-icon-button [icon]="AppIcon.IconHide"></app-circular-icon-button>
    <app-circular-icon-button [icon]="AppIcon.IconShow"></app-circular-icon-button>
    <app-circular-icon-button [icon]="AppIcon.IconDownload"></app-circular-icon-button>
    <app-circular-icon-button [icon]="AppIcon.IconEdit"></app-circular-icon-button>
    <app-circular-icon-button [icon]="AppIcon.IconX"></app-circular-icon-button>
    <app-circular-icon-button [icon]="AppIcon.ArrowDown"></app-circular-icon-button>
    <app-circular-icon-button [disabled]="true" [icon]="AppIcon.IconDelete"></app-circular-icon-button>
  </div>

  <h2>app-selectable-button with full-background theme</h2>
  <div class="selectable-button-container">
    <app-selectable-button>button-white-bg-select-state</app-selectable-button>
    <app-selectable-button [disabled]="true">button-white-bg-select-state-DEFAULT-INACTIVE</app-selectable-button>
    <app-selectable-button [selected]="true" [disabled]="true"
      >button-white-bg-select-state-DEFAULT-INACTIVE</app-selectable-button
    >
  </div>

  <h2>app-selectable-button with empty-background theme</h2>
  <div class="selectable-button-container">
    <app-selectable-button [theme]="'empty-background'">button-quick-filter</app-selectable-button>
    <app-selectable-button [theme]="'empty-background'" [disabled]="true"
      >button-quick-filte-INACTIVE</app-selectable-button
    >
    <app-selectable-button [theme]="'empty-background'" [selected]="true" [disabled]="true"
      >button-quick-filte-INACTIVE</app-selectable-button
    >
  </div>

  <h2>app-selectable-button with dot-cyan theme</h2>
  <div class="selectable-button-container">
    <app-selectable-button [theme]="'dot-cyan'">button-quick-filte-cyan</app-selectable-button>
    <app-selectable-button [theme]="'dot-cyan'" [disabled]="true"
      >button-quick-filte-cyan-DEFAULT-INACTIVE</app-selectable-button
    >
    <app-selectable-button [theme]="'dot-cyan'" [selected]="true" [disabled]="true"
      >button-quick-filte-cyan-DEFAULT-INACTIVE</app-selectable-button
    >
  </div>

  <h2>app-selectable-icon-button</h2>
  <div class="selectable-button-container">
    <app-selectable-icon-button [icon]="AppIcon.IconStar"></app-selectable-icon-button>
  </div>

  <div class="app-dropdown">
    <h1>Custom dropdown</h1>
    <app-dropdown [labelName]="'Label name'" [options]="options" [(ngModel)]="optionKey" />
    <app-dropdown [labelName]="'Label name'" [disabled]="true" [options]="options" [(ngModel)]="optionKey" />
    <div>Status dropdown</div>
    <app-dropdown [type]="DropdownType.Status" [options]="optionTaskStatus" [(ngModel)]="optionStatusKey" />
    <div>Status dropdown disabled</div>
    <app-dropdown
      [type]="DropdownType.Status"
      [options]="optionTaskStatus"
      [disabled]="true"
      [(ngModel)]="optionStatusKey"
    />
  </div>
</div>

<div class="app-button-tabs">
  <h1>Button tabs</h1>
  <app-tabs [tabs]="tabs" [type]="TabType.Highlight" (tabSelected)="selectedTab($event)"> </app-tabs>
</div>

<div class="app-button-tabs">
  <h1>Button tabs</h1>
  <app-tabs [tabs]="tabs" [type]="TabType.Highlight" (tabSelected)="selectedTab($event)"> </app-tabs>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountModel } from '../models/account.model';
import { ResponseModel } from '../models/response.model';
import { RequestParams } from '../utils/RequestParams';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  readonly myAccountUrl: string = `${environment.host}my-account`;
  readonly uploadProfilePictureUrl: string = `${environment.host}my-account/profile-picture`;

  constructor(private http: HttpClient) {}

  getMyAccount(params: RequestParams): Observable<AccountModel> {
    return this.http
      .get<ResponseModel<AccountModel>>(this.myAccountUrl, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: ResponseModel<AccountModel>) => new AccountModel(response.data)),
        catchError((error) => {
          throw error;
        }),
      );
  }

  updateMyAccount(accountFields: Partial<AccountModel>, params: RequestParams): Observable<AccountModel> {
    return this.http
      .patch<ResponseModel<AccountModel>>(this.myAccountUrl, accountFields, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: ResponseModel<AccountModel>) => new AccountModel(response.data)),
        catchError((error) => {
          throw error;
        }),
      );
  }

  uploadProfilePicture(imageUrl: string, params: RequestParams): Observable<AccountModel> {
    return this.http
      .post<ResponseModel<AccountModel>>(
        this.uploadProfilePictureUrl,
        { imageUrl: imageUrl },
        {
          params: params.getParamsObject(),
        },
      )
      .pipe(
        map((response: ResponseModel<AccountModel>) => new AccountModel(response.data)),
        catchError((error) => {
          throw error;
        }),
      );
  }

  deleteProfilePicture(params: RequestParams): Observable<AccountModel> {
    return this.http
      .delete<ResponseModel<AccountModel>>(this.uploadProfilePictureUrl, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: ResponseModel<AccountModel>) => new AccountModel(response.data)),
        catchError((error) => {
          throw error;
        }),
      );
  }
}

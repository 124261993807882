import { Component, Input } from '@angular/core';
import { AppIcon } from 'src/app/enums/icons.enum';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() disabled!: boolean;
  @Input() icon!: AppIcon;
  @Input() fill: 'blue' | 'white' = 'blue';
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

class AccountOption {
  title: string;
  disabled: boolean;
  action: Function;
  constructor(title: string, disabled: boolean, action: Function) {
    this.title = title;
    this.disabled = disabled;
    this.action = action;
  }
}

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
  options: AccountOption[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.options = [
      new AccountOption('account.title', false, this.navigateToAccount),
      new AccountOption('logout.title', false, this.logout),
    ];
  }

  navigateToAccount = (): void => {
    this.router.navigate(['/account']);
    this.closeMenu.emit();
  };

  logout = (): void => {
    this.authService.logout();
    this.closeMenu.emit();
  };
}

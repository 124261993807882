import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable } from 'rxjs';
import { ConsultantModel } from '../models/consultant.model';
import { ResponseModel } from '../models/response.model';
import { environment } from '../../environments/environment';
import { RequestParams } from '../utils/RequestParams';
import { ResponseError } from '../models/response-error.model';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  readonly consultantsUrl: string = `${environment.host}consultants`;

  constructor(private http: HttpClient) {}

  getConsultantsList(params: RequestParams): Observable<ConsultantModel[]> {
    return this.http
      .get<ResponseModel<ConsultantModel[]>>(this.consultantsUrl, { params: params.getParamsObject() })
      .pipe(
        map((response: ResponseModel<ConsultantModel[]>) => {
          return response.data.map((consultant) => new ConsultantModel(consultant));
        }),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  createConsultant(consultant: Partial<ConsultantModel>): Observable<ConsultantModel> {
    return this.http.post<ResponseModel<ConsultantModel>>(this.consultantsUrl, consultant).pipe(
      map((response: ResponseModel<ConsultantModel>) => {
        return new ConsultantModel(response.data);
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  updateConsultant(consultant: Partial<ConsultantModel>): Observable<ConsultantModel> {
    return this.http.patch<ResponseModel<ConsultantModel>>(`${this.consultantsUrl}/${consultant.id}`, consultant).pipe(
      map((response: ResponseModel<ConsultantModel>) => {
        return new ConsultantModel(response.data);
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  deleteConsultant(id: string): Observable<any> {
    return this.http.delete<ResponseModel<void>>(`${this.consultantsUrl}/${id}`).pipe(
      map((response: ResponseModel<void>) => {
        return response;
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  sendLoginReminder(id: string): Observable<ResponseModel<void>> {
    return this.http.post<ResponseModel<void>>(`${this.consultantsUrl}/${id}/send-login-reminder`, {}).pipe(
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }
}

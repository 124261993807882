import { AccountRole } from '../enums/account-role.enum';
import { ImageFile } from './image-file.model';

export class AccountModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  profilePicture: ImageFile;
  profilePictureUrl: string;
  role: AccountRole;

  companyName: string;
  registrationNumber: string;
  businessIndustry: string;
  businessDescription: string;
  streetAndNumber: string;
  buildingDescription: string;
  city: string;
  county: string;
  country: string;

  constructor(accountObj: any) {
    this.id = accountObj && accountObj.id ? accountObj.id : null;
    this.firstName = accountObj && accountObj.firstName ? accountObj.firstName : null;
    this.lastName = accountObj && accountObj.lastName ? accountObj.lastName : null;
    this.email = accountObj && accountObj.email ? accountObj.email : null;
    this.phone = accountObj && accountObj.phone ? accountObj.phone : null;
    this.password = accountObj && accountObj.password ? accountObj.password : '*****************';
    this.profilePicture = accountObj && new ImageFile(null, null, accountObj.profilePictureUrl);
    this.profilePictureUrl = accountObj && accountObj.profilePictureUrl ? accountObj.profilePictureUrl : null;
    this.companyName = accountObj && accountObj.companyName ? accountObj.companyName : null;
    this.registrationNumber = accountObj && accountObj.registrationNumber ? accountObj.registrationNumber : null;
    this.businessIndustry = accountObj && accountObj.businessIndustry ? accountObj.businessIndustry : null;
    this.businessDescription = accountObj && accountObj.businessDescription ? accountObj.businessDescription : null;
    this.streetAndNumber = accountObj && accountObj.streetAndNumber ? accountObj.streetAndNumber : null;
    this.buildingDescription = accountObj && accountObj.buildingDescription ? accountObj.buildingDescription : null;
    this.city = accountObj && accountObj.city ? accountObj.city : null;
    this.county = accountObj && accountObj.county ? accountObj.county : null;
    this.country = accountObj && accountObj.country ? accountObj.country : null;
    this.role = accountObj && accountObj.role ? accountObj.role : null;
  }

  get initials(): string {
    return (this.firstName?.charAt(0) || '').toUpperCase() + (this.lastName?.charAt(0) || '')?.toUpperCase();
  }

  set all(accountObj: any) {
    this.firstName = accountObj && accountObj.firstName ? accountObj.firstName : this.firstName;
    this.lastName = accountObj && accountObj.lastName ? accountObj.lastName : this.lastName;
    this.email = accountObj && accountObj.email ? accountObj.email : this.email;
    this.phone = accountObj && accountObj.phone ? accountObj.phone : this.phone;
    this.password = accountObj && accountObj.password ? accountObj.password : this.password;
    this.profilePicture = accountObj ? new ImageFile(null, null, accountObj.profilePictureUrl) : this.profilePicture;
    this.profilePictureUrl =
      accountObj && accountObj.profilePictureUrl ? accountObj.profilePictureUrl : this.profilePictureUrl;
    this.companyName = accountObj && accountObj.companyName ? accountObj.companyName : this.companyName;
    this.registrationNumber =
      accountObj && accountObj.registrationNumber ? accountObj.registrationNumber : this.registrationNumber;
    this.businessIndustry =
      accountObj && accountObj.businessIndustry ? accountObj.businessIndustry : this.businessIndustry;
    this.businessDescription =
      accountObj && accountObj.businessDescription ? accountObj.businessDescription : this.businessDescription;
    this.streetAndNumber = accountObj && accountObj.streetAndNumber ? accountObj.streetAndNumber : this.streetAndNumber;
    this.buildingDescription =
      accountObj && accountObj.buildingDescription ? accountObj.buildingDescription : this.buildingDescription;
    this.city = accountObj && accountObj.city ? accountObj.city : this.city;
    this.county = accountObj && accountObj.county ? accountObj.county : this.county;
    this.country = accountObj && accountObj.country ? accountObj.country : this.country;
    this.role = accountObj && accountObj.role ? accountObj.role : this.role;
  }
}

<div class="dropdown position-relative" [ngClass]="{ status: type === DropdownType.Status }">
  <div class="position-absolute" [ngClass]="{ 'is-open': isDropdownOpen }">
    <div class="label-container" [ngClass]="{ disabled: disabled, 'no-padding': !labelName }">
      {{ labelName | translate }}
    </div>
    <div
      (clickOutside)="onClickOutside()"
      (click)="toggleDropdown()"
      (keydown)="onKeyDown($event)"
      [ngClass]="{ disabled: disabled, 'is-open': isDropdownOpen, error: error }"
      class="dropdown-container"
      *ngIf="isEditMode; else isViewMode"
    >
      <div
        class="input-container {{ type }}"
        [tabindex]="disabled ? -1 : 0"
        [ngClass]="{ disabled: disabled, 'is-open': isDropdownOpen, 'has-search': hasSearch }"
        [ngStyle]="{ 'background-color': isDropdownOpen ? '' : selectedOption.color }"
      >
        <div class="bg-effect"></div>
        <div class="check-icon" *ngIf="type === DropdownType.Status && isDropdownOpen && selectedOption">
          <svg-icon [src]="AppIcon.IconCheckLarge" [ngStyle]="{ fill: selectedOption.color }" />
        </div>
        <input
          #input
          *ngIf="hasSearch; else noSearch"
          class="input"
          [ngModel]="inputValue | translate"
          (ngModelChange)="onInput($event)"
          [placeholder]="placeholder | translate"
        />
        <ng-template #noSearch>
          <div
            [ngClass]="{ placeholder: placeholder }"
            [ngStyle]="{ color: isDropdownOpen ? selectedOption.color : '' }"
          >
            {{ selectedOption.value || placeholder | translate }}
          </div>
        </ng-template>
        <div class="arrow-icon">
          <svg-icon
            *ngIf="type === DropdownType.Default || (type === DropdownType.Status && !isDropdownOpen)"
            [src]="AppIcon.ArrowDown"
          />
        </div>
      </div>

      <hr *ngIf="type === DropdownType.Default && isDropdownOpen" />

      <div class="menu-container" [ngClass]="{ hidden: !isDropdownOpen }" [ngSwitch]="type">
        <div
          *ngSwitchCase="DropdownType.Default"
          class="options-container"
          [ngClass]="{ 'no-results': !filteredOptions.length }"
        >
          <a
            class="option"
            tabindex="-1"
            #dropdownOptions
            *ngFor="let option of filteredOptions"
            (click)="selectOption(option)"
          >
            {{ option?.value | translate }}
            <svg-icon *ngIf="option?.key === selectedOption.key" [src]="AppIcon.IconCheckLarge" />
          </a>
          <div *ngIf="!filteredOptions.length" class="no-results">{{ 'common.noResults' | translate }}</div>
        </div>
        <div *ngSwitchCase="DropdownType.Status" class="status-options-container">
          <a
            #dropdownStatusOptions
            tabindex="-1"
            class="option"
            [ngStyle]="{ color: option?.color }"
            (click)="selectOption(option)"
            *ngFor="let option of statusOptions"
            id="{{ option?.key }}"
          >
            {{ option?.value | translate }}
          </a>
        </div>
      </div>
    </div>
    <ng-template #isViewMode>
      <div class="value">{{ selectedOption.value || '-' }}</div>
    </ng-template>
  </div>
  <div *ngIf="error" class="error-message">{{ error | translate }}</div>
</div>

import { ErrorCode } from '../enums/http-request-error.enum';
import { HttpRequestStatus } from '../enums/http-request-status.enum';

export class ResponseError {
  code!: ErrorCode;
  status!: HttpRequestStatus;
  message!: string;
  data: any;

  constructor(errorObj: any) {
    this.code = errorObj?.error?.code;
    this.message = errorObj?.error?.message;
    this.data = errorObj?.error?.data;
    this.status = errorObj?.status;
  }

  get errorMessage() {
    return this.message;
  }
}

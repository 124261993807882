<div class="dropzone" [ngClass]="{ 'disabled-state': disabled, 'error-state': error }">
  <div *ngIf="!fileName" class="no-file">
    <input type="file" [disabled]="disabled" (change)="onFileSelected($event)" #fileUpload />
    <app-svg-icon [src]="AppIcon.IconUpload" class="blue" />
    {{ 'component.dragNDrop' | translate }}
    <div class="hover-effect"></div>
  </div>

  <div *ngIf="fileName" class="file-container">
    {{ fileName }}
    <app-svg-icon [src]="AppIcon.IconX" class="blue" (click)="onFileRemoved()" />
  </div>
</div>

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppIcon } from 'src/app/enums/icons.enum';
import { InputFormat } from 'src/app/enums/input-format.enum';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent implements ControlValueAccessor {
  @Input() labelName!: string;
  @Input() labelTheme!: 'light' | 'dark';
  @Input() format: InputFormat = InputFormat.Default;
  @Input() inputTheme: 'light' | 'dark' | 'blue' = 'dark';
  @Input() type!: string;
  @Input() disabled: boolean = false;
  @Input() error!: string;
  @Input() picker: any;
  @Input() placeholder: string = '';
  @Input() placeholderTheme: 'default' | 'blue' | 'white' = 'default';
  @Input() isEditMode: boolean = true;
  @Input() readonly: boolean = false;
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  value: any;
  isTouched: boolean = false;
  isDisabled: boolean = false;

  //enums
  AppIcon = AppIcon;
  InputFormat = InputFormat;

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInput(value: string) {
    this.markAsTouched();
    if (!this.isDisabled) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.onTouched();
      this.isTouched = true;
    }
  }

  // only for InputFormat.Password
  isShown: boolean = false;

  toggleShow(isShown: boolean) {
    this.isShown = isShown;
  }

  // only for InputFormat.DatePicker
  onDateChanged(date: Date): void {
    this.value = date;
    this.onChange(this.value);
    this.dateChanged.emit(date);
  }
}

<button
  [class]="theme"
  [ngClass]="{
    'disabled-state': disabled,
    'full-size': fullSize
  }"
  [disabled]="disabled"
  [type]="type"
>
  <ng-content></ng-content>
  <div class="hover-effect"></div>
</button>

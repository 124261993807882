import { Component, Input } from '@angular/core';
import { AppIcon } from 'src/app/enums/icons.enum';

@Component({
  selector: 'app-circular-icon-button',
  templateUrl: './circular-icon-button.component.html',
  styleUrls: ['./circular-icon-button.component.scss'],
})
export class CircularIconButtonComponent {
  @Input() disabled!: boolean;
  @Input() icon!: AppIcon;
  @Input() type!: string;
}

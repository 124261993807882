import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertComponent } from '../components/alert/alert.component';
import { AlertType } from '../enums/alert-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  open<T>(component: ComponentType<T>, config?: MatDialogConfig) {
    const dialogRef = this.dialog.open(component, config);

    return dialogRef.afterClosed();
  }

  openSuccess(d?: { title?: string; subtitle?: string }) {
    const dialogData = { title: d?.title, subtitle: d?.subtitle || 'alert.success', type: AlertType.Success };
    return this.open<AlertComponent>(AlertComponent, {
      panelClass: 'success-alert',
      hasBackdrop: false,
      position: {
        top: '10px',
      },
      data: dialogData,
    });
  }

  openWarning(d?: { title?: string; subtitle?: string; positiveButtonText?: string }) {
    const dialogData = {
      title: d?.title || 'alert.warning',
      subtitle: d?.subtitle,
      type: AlertType.Warning,
      positiveButtonText: d?.positiveButtonText,
    };
    return this.open<AlertComponent>(AlertComponent, { panelClass: 'modal-form', data: dialogData });
  }

  openError(d?: { title?: string; subtitle?: string }) {
    const dialogData = { title: d?.title || 'alert.error', subtitle: d?.subtitle, type: AlertType.Error };
    return this.open<AlertComponent>(AlertComponent, { panelClass: 'modal-form', data: dialogData });
  }
}

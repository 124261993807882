import { ClientModel } from './client.model';
import { ConsultantModel } from './consultant.model';
import { ProjectStatus } from '../enums/project-status.enum';

export class ProjectModel {
  id: string;
  name: string;
  description: string;
  deadline: string;
  status: ProjectStatus;
  client?: ClientModel;
  consultant?: ConsultantModel;

  constructor(projectObj: any) {
    this.id = projectObj?.id;
    this.name = projectObj?.name;
    this.description = projectObj?.description;
    this.deadline = projectObj?.deadline;
    this.status = projectObj?.status || ProjectStatus.CONFIGURE_DIAGNOSTIC;
    this.consultant = projectObj?.consultant ? new ConsultantModel(projectObj.consultant) : undefined;
    this.client = projectObj?.client ? new ClientModel(projectObj.client) : undefined;
  }

  get consultantId(): string | undefined {
    return this.consultant?.id;
  }
}

export class CreateUpdateProjectModel {
  name?: string;
  description?: string;
  deadline?: Date;
  status: ProjectStatus;
  consultantId?: string;
  clientId?: string;

  constructor(obj: any) {
    this.name = obj?.name || undefined;
    this.description = obj?.description || undefined;
    this.deadline = obj?.deadline || undefined;
    this.status = obj?.status || ProjectStatus.CONFIGURE_DIAGNOSTIC;
    this.consultantId = obj?.consultantId || undefined;
    this.clientId = obj?.clientId || undefined;
  }
}

import { Component } from '@angular/core';
import { AppIcon } from 'src/app/enums/icons.enum';
import { AccountModel } from 'src/app/models/account.model';
import { AuthService } from 'src/app/services/auth.service';

const appSidebarPages = [
  {
    key: 'tasks',
    title: 'tasks.title',
    icon: AppIcon.IconTasks,
  },
  {
    key: 'projects',
    title: 'projects.title',
    icon: AppIcon.IconProjects,
  },
  {
    key: 'clients',
    title: 'clients.title',
    icon: AppIcon.IconUserManagement,
  },
  {
    key: 'consultants',
    title: 'consultants.title',
    icon: AppIcon.IconConsultants,
  },
  {
    key: 'questions',
    title: 'questions.title',
    icon: AppIcon.IconQuestions,
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  //enums
  AppIcon = AppIcon;

  appSidebarPages = appSidebarPages;
  isAccountMenuOpen: boolean = false;

  account!: AccountModel | null;

  constructor(private authService: AuthService) {
    this.authService.currentAccount$.subscribe((currentAccount) => {
      this.account = currentAccount;
    });
  }

  toggleAccountMenu() {
    this.isAccountMenuOpen = !this.isAccountMenuOpen;
  }

  onClickOutside(): void {
    this.isAccountMenuOpen = false;
  }
}

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AppIcon } from '../../enums/icons.enum';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatepickerType, DateType } from '../../enums/datepicker-type.enum';
import { InputFormat } from 'src/app/enums/input-format.enum';

const DATEPICKER_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthLabel: 'MMMM',
    monthYearLabel: 'MMMM YYYY',
  },
};

export class RangeDates {
  startDate: Date | null;
  endDate: Date | null;
  constructor(startDate?: Date, endDate?: Date) {
    this.startDate = startDate || null;
    this.endDate = endDate || null;
  }
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATEPICKER_DATE_FORMAT,
    },
  ],
})
export class DatepickerComponent {
  @Input() labelName: string = '';
  @Input() disabled: boolean = false;
  @Input() error: string = '';
  @Input() type: DatepickerType = DatepickerType.Default;
  @Input() isEditMode: boolean = true;
  @Output() dateChanged: EventEmitter<Date | null> = new EventEmitter<Date | null>();
  @Output() rangeDatesChanged: EventEmitter<RangeDates> = new EventEmitter<RangeDates>();

  readonly AppIcon = AppIcon;
  readonly DatepickerType = DatepickerType;
  readonly DateType = DateType;
  readonly InputFormat = InputFormat;

  rangeDates: RangeDates = new RangeDates();
  value?: RangeDates | Date | null;

  onDateChange(date: Date | null, dateType: DateType): void {
    switch (dateType) {
      case DateType.Default:
        this.onChange(date);
        this.value = date;
        this.dateChanged.emit(date);
        break;
      case DateType.Start:
        this.rangeDates.startDate = date;
        break;
      case DateType.End:
        this.rangeDates.endDate = date;
        this.onChange(this.rangeDates);
        if (this.rangeDates.endDate) this.rangeDatesChanged.emit(this.rangeDates);
        break;
    }
  }

  onChange = (value: RangeDates | Date | null) => {};

  onClearRange() {
    this.rangeDates.startDate = null;
    this.rangeDates.endDate = null;
    this.onChange(this.rangeDates);
    this.rangeDatesChanged.emit(this.rangeDates);
  }

  onClearDate() {
    this.onChange(null);
    this.value = null;
    this.dateChanged.emit(null);
  }

  writeValue(value: RangeDates | Date): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}

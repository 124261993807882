import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { ButtonComponent } from './buttons/button/button.component';
import { InputTextComponent } from './input-text/input-text.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TextButtonComponent } from './buttons/text-button/text-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconCircleComponent } from './icon-circle/icon-circle.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { IconButtonComponent } from './buttons/icon-button/icon-button.component';
import { CircularIconButtonComponent } from './buttons/circular-icon-button/circular-icon-button.component';
import { SelectableButtonComponent } from './buttons/selectable-button/selectable-button.component';
import { SelectableIconButtonComponent } from './buttons/selectable-icon-button/selectable-icon-button.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DirectivesModule } from '../directives/directives.module';
import { TabsComponent } from './tabs/tabs.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule } from '@angular/forms';
import { HighlightTextComponent } from './highlight-text/highlight-text.component';
import { FileLoaderComponent } from './file-loader/file-loader.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { PipesModule } from '../pipes/pipes.module';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyTheme2, LY_THEME_NAME, LY_THEME } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { DocumentComponent } from './document/document.component';
import { CardComponent } from './card/card.component';
import { AlertComponent } from './alert/alert.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { LottieModule } from 'ngx-lottie';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [
    SvgIconComponent,
    InputTextComponent,
    ButtonComponent,
    TextButtonComponent,
    SidebarComponent,
    IconCircleComponent,
    IconButtonComponent,
    CircularIconButtonComponent,
    SelectableButtonComponent,
    SelectableIconButtonComponent,
    DropdownComponent,
    FileLoaderComponent,
    TabsComponent,
    SearchBarComponent,
    HighlightTextComponent,
    DatepickerComponent,
    AccountMenuComponent,
    DocumentComponent,
    CardComponent,
    AlertComponent,
    TextAreaComponent,
    CheckboxComponent,
    LoadingComponent,
  ],
  exports: [
    SvgIconComponent,
    InputTextComponent,
    ButtonComponent,
    TextButtonComponent,
    SidebarComponent,
    IconCircleComponent,
    IconButtonComponent,
    CircularIconButtonComponent,
    SelectableButtonComponent,
    SelectableIconButtonComponent,
    DropdownComponent,
    FileLoaderComponent,
    TabsComponent,
    SearchBarComponent,
    HighlightTextComponent,
    DatepickerComponent,
    AccountMenuComponent,
    DocumentComponent,
    CardComponent,
    AlertComponent,
    TextAreaComponent,
    CheckboxComponent,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    DirectivesModule,
    TranslateModule.forChild({
      extend: true,
    }),
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    PipesModule,
    LyImageCropperModule,
    LottieModule,
  ],
  providers: [
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
  ],
})
export class ComponentsModule {}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputFormat } from 'src/app/enums/input-format.enum';

@Component({
  selector: 'app-sandbox-modal',
  templateUrl: './sandbox-modal.component.html',
  styleUrls: ['./sandbox-modal.component.scss'],
})
export class SandboxModalComponent {
  InputFormat = InputFormat;

  constructor(
    public dialogRef: MatDialogRef<SandboxModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  submit(form: any) {
    if (form.valid) this.dialogRef.close(form.controls);
  }

  close(): void {
    this.dialogRef.close();
  }
}

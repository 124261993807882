<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="AlertType.Success" class="success-alert">
    <app-svg-icon [src]="AppIcon.IconCheckLargeSuccess" />
    <div class="content">
      <div *ngIf="subtitle" class="subtitle">
        {{ subtitle | translate }}
      </div>
    </div>
    <div class="separator"></div>
    <app-icon-button [icon]="AppIcon.IconX" (click)="cancel()" />
  </div>

  <div *ngSwitchCase="AlertType.Warning" class="warning-alert">
    <div class="top-container">
      <app-svg-icon [src]="AppIcon.IconWarning" />
      <div class="content">
        <div class="title">{{ title | translate }}</div>
        <div *ngIf="subtitle" class="subtitle">
          {{ subtitle | translate }}
        </div>
      </div>
    </div>
    <hr />
    <div class="buttons">
      <app-button (click)="confirm()">
        {{ positiveButtonText | translate }}
      </app-button>
      <app-button theme="grey" (click)="cancel()">
        {{ 'button.cancel' | translate }}
      </app-button>
    </div>
  </div>

  <div *ngSwitchCase="AlertType.Error" class="error-alert">
    <div class="top-container">
      <app-svg-icon [src]="AppIcon.IconError" />
      <div class="content">
        <div class="title">{{ title | translate }}</div>
        <div *ngIf="subtitle" class="subtitle">
          {{ subtitle | translate }}
        </div>
      </div>
    </div>
    <hr />
    <div class="buttons">
      <app-button (click)="confirm()">
        {{ positiveButtonText | translate }}
      </app-button>
    </div>
  </div>
</ng-container>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-selectable-button',
  templateUrl: './selectable-button.component.html',
  styleUrls: ['./selectable-button.component.scss'],
})
export class SelectableButtonComponent implements ControlValueAccessor {
  @Input() disabled!: boolean;
  @Input() selected!: boolean;
  @Input() theme:
    | 'full-background'
    | 'empty-background'
    | 'dot-green'
    | 'dot-orange'
    | 'dot-red'
    | 'dot-purple'
    | 'dot-cyan' = 'full-background';
  @Output() onSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange: (select: boolean) => void = () => {};

  toggle() {
    this.selected = !this.selected;
    this.onSelect.emit(this.selected);
    this.onChange(this.selected);
  }

  get dot(): boolean {
    return this.theme.includes('dot');
  }

  get state(): string {
    return this.selected ? 'selected' : 'default';
  }

  writeValue(value: boolean): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}

import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() size: 'big' | 'small' = 'big';

  options: AnimationOptions = {
    path: '../../../assets/animations/loader.json',
  };
}

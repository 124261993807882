import { Injectable } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, BehaviorSubject } from 'rxjs';

type SearchObject = {
  userInput$: Subject<string>;
  searchString$: Subject<string>;
};

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchObjects: Map<string, SearchObject> = new Map<string, SearchObject>();

  constructor() {
    // Preserve old default behaviour
    this.registerSearch('search');
  }

  registerSearch(searchKey: string, params: { debounceTime: number } = { debounceTime: 300 }) {
    const searchObject = {
      userInput$: new Subject<string>(),
      searchString$: new Subject<string>(),
    };

    searchObject.userInput$.pipe(debounceTime(params.debounceTime), distinctUntilChanged()).subscribe((userInput) => {
      searchObject.searchString$.next(userInput);
    });

    this.searchObjects.set(searchKey, searchObject);
    return searchObject;
  }

  deregisterSearch(searchKey: string) {
    if (this.searchObjects.has(searchKey)) {
      this.searchObjects.delete(searchKey);
    }
  }

  getUserInputSubject(searchKey: string) {
    return this.searchObjects.get(searchKey)?.userInput$;
  }

  getSearchString(searchKey: string) {
    return this.searchObjects.get(searchKey)?.searchString$;
  }
}

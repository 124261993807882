import { Component, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { AccountIconSize } from '../../enums/account-icon-size.enum';
import { AppIcon } from 'src/app/enums/icons.enum';
import { ImageFile } from 'src/app/models/image-file.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LyImageCropper, ImgCropperConfig, ImgCropperEvent, ImgCropperErrorEvent } from '@alyle/ui/image-cropper';

@Component({
  selector: 'app-icon-circle',
  templateUrl: './icon-circle.component.html',
  styleUrls: ['./icon-circle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconCircleComponent),
      multi: true,
    },
  ],
})
export class IconCircleComponent implements ControlValueAccessor {
  readonly AppIcon = AppIcon;

  @Input() initials: string = '';
  @Input() size: AccountIconSize = AccountIconSize.Small;
  @Input() isEditMode: boolean = false;
  @Input() theme?: string;
  @Input() imageUrl: string | undefined = '';

  imageFile!: ImageFile | null;
  @ViewChild(LyImageCropper, { static: true }) readonly cropper!: LyImageCropper;
  cropConfig: ImgCropperConfig = {
    width: 230,
    height: 230,
    round: true,
    autoCrop: true,
    output: {
      width: 230,
      height: 230,
    },
  };

  constructor() {}

  onChange = (imageFile: ImageFile | null) => {};

  writeValue(data: ImageFile | null): void {
    this.imageFile = new ImageFile(data?.original, data?.cropped, data?.url);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
  onDelete = () => {
    if (this.imageFile) {
      this.imageFile.url = '';
      this.imageFile.cropped = null;
      this.onChange(this.imageFile);
    }
    this.cropper.clean();
  };
  onCleaned() {
    if (this.imageFile) {
      this.imageFile.url = '';
      this.imageFile.cropped = null;
      this.imageFile.original = null;
      this.onChange(this.imageFile);
    }
  }
  onCropped(e: ImgCropperEvent) {
    if (this.imageFile) this.imageFile.cropped = e;
    this.onChange(this.imageFile);
  }
  onLoaded(e: ImgCropperEvent) {
    this.imageFile = new ImageFile(e, null);
    this.cropper.crop();
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }

  finalImageUrl() {
    return this.imageUrl || this.imageFile?.url || '';
  }

  displayInitials() {
    return !this.finalImageUrl() && !this.imageFile?.cropped;
  }

  displayLocalImage() {
    return !this.finalImageUrl() && this.imageFile?.cropped;
  }
}

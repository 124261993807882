import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownType } from '../../enums/dropdown-type.enum';
import { AppIcon } from '../../enums/icons.enum';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
  @Input() options!: DropdownOption[];
  @Input() labelName: string = '';
  @Input() type: DropdownType = DropdownType.Default;
  @Input() disabled: boolean = false;
  @Input() isEditMode: boolean = true;
  @Input() placeholder: string = '';
  @Input() error!: string;
  @Input() hasSearch?: boolean = false;
  @Output() optionSelected: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();

  @ViewChild('input') inputRef?: ElementRef;
  @ViewChildren('dropdownOptions') dropdownOptionsRef?: QueryList<ElementRef>;
  @ViewChildren('dropdownStatusOptions') dropdownStatusOptionsRef?: QueryList<ElementRef>;

  DropdownType = DropdownType;
  AppIcon = AppIcon;

  selectedOption!: DropdownOption;
  isDropdownOpen: boolean = false;
  activeOptionIndex: number = -1;
  filteredOptions: DropdownOption[] = [];
  inputValue: string = '';

  constructor() {
    this.selectedOption = new DropdownOption({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']?.currentValue.length && !changes['options']?.previousValue?.length) {
      this.filteredOptions = this.options;
    }
  }

  get statusOptions(): any[] {
    return this.options?.filter((option) => option?.key !== this.selectedOption.key);
  }

  toggleDropdown(): void {
    if (this.isDropdownOpen) this.closeDropdown();
    else {
      this.openDropdown();
      this.inputRef?.nativeElement.focus();
      this.inputValue = '';
    }
  }

  openDropdown(): void {
    this.isDropdownOpen = true;
    this.onKeyDownOption = this.onKeyDownOption.bind(this);
    document.addEventListener('keydown', this.onKeyDownOption);
  }

  closeDropdown(): void {
    this.isDropdownOpen = false;
    document.removeEventListener('keydown', this.onKeyDownOption);
  }

  onClickOutside(): void {
    this.isDropdownOpen = false;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event?.key === 'Enter' && !this.isDropdownOpen) {
      this.openDropdown();
      setTimeout(() => {
        if (this.type === DropdownType.Default) this.dropdownOptionsRef?.first?.nativeElement.focus();
        else this.dropdownStatusOptionsRef?.first?.nativeElement.focus();
      }, 1);
    }
  }

  onKeyDownOption(event: KeyboardEvent): void {
    event.stopPropagation();
    const ref = this.type === DropdownType.Default ? this.dropdownOptionsRef : this.dropdownStatusOptionsRef;
    const focusedElement = ref?.find((option, index) => {
      this.activeOptionIndex = index;
      return option.nativeElement === document.activeElement;
    });

    switch (event?.key) {
      case 'ArrowUp':
        (this.activeOptionIndex === 0 ? ref?.last : ref?.get(this.activeOptionIndex - 1))?.nativeElement.focus();
        break;
      case 'ArrowDown':
        (this.activeOptionIndex === (ref?.length || 0) - 1
          ? ref?.first
          : ref?.get(this.activeOptionIndex + 1)
        )?.nativeElement.focus();
        break;
      case 'Enter':
        if (focusedElement) {
          if (this.type === DropdownType.Default) {
            this.selectOption(this.filteredOptions[this.activeOptionIndex]);
          } else {
            this.selectOption(
              this.filteredOptions.find((option) => option?.key === parseInt(focusedElement.nativeElement.id)),
            );
          }
          this.closeDropdown();
        }
        break;
    }
  }

  selectOption(option?: DropdownOption): void {
    if (option) {
      this.selectedOption = new DropdownOption(option);
      this.onChange(option.key);
      this.optionSelected.emit(option);
      this.filteredOptions = this.options;
      this.inputValue = option.value;
    }
  }

  onInput(value: string): void {
    if (value) {
      this.filteredOptions = this.options.filter((option) => option.value.toLowerCase().includes(value.toLowerCase()));
    } else {
      this.filteredOptions = this.options;
    }
  }

  onChange = (key: any) => {};

  writeValue(key: any): void {
    this.selectedOption = this.options?.find((o) => o?.key == key)! || {};
    this.inputValue = this.selectedOption?.value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}

export class DropdownOption {
  key: any;
  value: any;
  color: string;

  constructor(optionObj: any) {
    this.key = optionObj?.key;
    this.value = optionObj?.value;
    this.color = optionObj?.color;
  }
}

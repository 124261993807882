<div class="document-container">
  <div class="icon-cell">
    <app-svg-icon [src]="AppIcon.IconDocument" />
    <div class="buttons-cell">
      <app-icon-button [icon]="AppIcon.IconShow" (click)="view()"></app-icon-button>
      <app-icon-button [icon]="AppIcon.IconDownload" (click)="download()"></app-icon-button>
      <app-icon-button [icon]="AppIcon.IconDelete" (click)="delete()"></app-icon-button>
    </div>
  </div>
  <div class="name-cell">{{ documentName }}</div>
</div>

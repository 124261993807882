import { Component, Inject } from '@angular/core';
import { AlertType } from '../../enums/alert-type.enum';
import { AppIcon } from '../../enums/icons.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  title: string;
  subtitle?: string;
  type: AlertType;
  positiveButtonText: string;

  readonly AppIcon = AppIcon;
  readonly AlertType = AlertType;

  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.type = data.type || AlertType.Success;
    this.positiveButtonText = data.positiveButtonText || 'button.ok';
    if (this.type === AlertType.Success) {
      setTimeout((): void => {
        this.dialogRef.close(true);
      }, 3000);
    }
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}

export enum FilterOperation {
  IN,
  GT,
  GTE,
  LT,
  LTE,
  NE,
}

export type Filter = {
  fieldName: string;
  operation?: FilterOperation;
  value: any;
};

export type OrderBy = {};

export class RequestParams {
  search: string = '';
  filters: Filter[] = [];
  orderBy: OrderBy[] = [];
  fields: string[] = [];
  limit: number = 0;
  offset: number = 0;

  constructor(params: Partial<RequestParams>) {
    Object.assign(this, params);
  }

  getParamsObject() {
    let result: any = {};

    if (this.search) {
      result.search = this.search;
    }

    if (this.filters) {
      result = {
        ...result,
        ...this.processFilters(),
      };
    }

    if (this.orderBy) {
      // TODO: Format and add order by
    }

    if (this.fields) {
      result.fields = this.fields;
    }

    if (this.limit) {
      result.limit = this.limit;
    }

    if (this.offset) {
      result.offset = this.offset;
    }

    return result;
  }

  private processFilters() {
    const result: any = {};

    this.filters.forEach((filter) => {
      if (!filter.operation) {
        result[filter.fieldName] = filter.value;
      } else {
        let filterOp = '';
        switch (filter.operation as FilterOperation) {
          case FilterOperation.GT:
            filterOp = 'gt';
            break;
          case FilterOperation.GTE:
            filterOp = 'gte';
            break;
          case FilterOperation.IN:
            filterOp = 'in';
            break;
          case FilterOperation.LT:
            filterOp = 'lt';
            break;
          case FilterOperation.LTE:
            filterOp = 'lte';
            break;
          case FilterOperation.NE:
            filterOp = 'ne';
            break;
        }
        const filterKey = `${filter.fieldName}__${filterOp}`;
        result[filterKey] = filter.value;
      }
    });

    return result;
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from './local-storage.service';
import { AuthToken } from '../models/auth-token.model';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;
    if (!headers.has('Content-Type')) {
      headers = headers.set('Content-Type', 'application/json');
    }

    const authToken: AuthToken | null = LocalStorageService.getAuthToken();
    if (authToken) {
      headers = headers.set('Authorization', 'Bearer ' + authToken.accessToken);
    }

    const reqClone: HttpRequest<any> = req.clone({
      headers: headers,
    });

    return next.handle(reqClone).pipe(
      catchError((response: any): Observable<any> => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            return this.handleUnauthorized(reqClone, response, next);
          }
        }
        return throwError(() => response);
      }),
    );
  }

  handleUnauthorized(
    interceptedRequest: HttpRequest<any>,
    interceptedResponse: HttpErrorResponse,
    requestHandler: HttpHandler,
  ) {
    if (interceptedRequest.url === this.authService.loginUrl) {
      this.authService.clearDataAndGoToLogin();
      return throwError(() => interceptedResponse);
    }

    return this.authService.refreshToken().pipe(
      mergeMap((): Observable<any> => {
        let headers: HttpHeaders = interceptedRequest.headers;
        const authToken: AuthToken | null = LocalStorageService.getAuthToken();
        if (authToken) {
          headers = headers.set('Authorization', 'Bearer ' + authToken.accessToken);
        }
        interceptedRequest = interceptedRequest.clone({ headers: headers });
        return requestHandler.handle(interceptedRequest);
      }),
    );
  }
}

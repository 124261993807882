import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { LoadingComponent } from '../components/loading/loading.component';

const OVERLAY_CLASS = 'loading-overlay';
const BG_CLASS = 'loading-background';

@Directive({
  selector: '[appIsLoading]',
})
export class LoadingDirective implements OnChanges {
  @Input('appIsLoading') isLoading = false;
  @Input() loadingBackground: boolean = false;
  @Input() loadingSize: 'big' | 'small' = 'big';

  protected overlayElement!: HTMLDivElement;
  protected loadingElement!: HTMLDivElement;
  protected hostElement!: HTMLDivElement;

  constructor(
    protected readonly elementRef: ElementRef,
    protected readonly renderer: Renderer2,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly viewContainerRef: ViewContainerRef,
  ) {
    this.hostElement = this.elementRef.nativeElement;
    this.hostElement.style.position = 'relative';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.overlayElement || !this.loadingElement) this.init();
    else this.initLoadingComponent();

    if (changes['isLoading']) {
      changes['isLoading'].currentValue ? this.addLoadingIndicator() : this.removeLoadingIndicator();
      this.changeDetectorRef.markForCheck();
    }

    changes['loadingBackground']?.currentValue ? this.addOverlayBackground() : this.removeOverlayBackground();
  }

  protected addLoadingIndicator(): void {
    this.renderer.appendChild(this.hostElement, this.overlayElement);
    this.renderer.appendChild(this.overlayElement, this.loadingElement);
  }

  protected removeLoadingIndicator(): void {
    this.renderer.removeChild(this.overlayElement, this.loadingElement);
    this.renderer.removeChild(this.hostElement, this.overlayElement);
    this.viewContainerRef.clear();
  }

  protected init(): void {
    this.initOverlayElement();
    this.initLoadingComponent();
  }

  protected initLoadingComponent(): void {
    let component = this.viewContainerRef.createComponent(LoadingComponent);
    component.instance.size = this.loadingSize;
    this.loadingElement = component.location.nativeElement;
  }

  protected initOverlayElement(): void {
    this.overlayElement = this.renderer.createElement('div');
    this.renderer.addClass(this.overlayElement, OVERLAY_CLASS);
    this.addOverlayBackground();
  }

  protected addOverlayBackground(): void {
    if (this.loadingBackground) this.renderer.addClass(this.overlayElement, BG_CLASS);
  }

  protected removeOverlayBackground(): void {
    if (!this.loadingBackground) this.renderer.removeClass(this.overlayElement, BG_CLASS);
  }
}

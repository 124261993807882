<div [ngSwitch]="format">
  <label [ngClass]="{ 'disabled-state': disabled }">
    <div class="label-container {{ labelTheme }}" [ngClass]="{ 'no-padding': !labelName }">
      {{ labelName | translate }}
    </div>
    <div
      class="input-container {{ inputTheme }}"
      [ngClass]="{ 'error-state': error, 'disabled-state': disabled, hide: !isEditMode, 'read-only': readonly }"
    >
      <span *ngSwitchCase="InputFormat.Default">
        <input
          [(ngModel)]="value"
          class="{{ placeholderTheme }}"
          [ngClass]="{ disabled: disabled }"
          [type]="type"
          [disabled]="disabled"
          [placeholder]="placeholder | translate"
          (ngModelChange)="onInput($event)"
        />
      </span>

      <span *ngSwitchCase="InputFormat.Password" class="password-format">
        <input
          [(ngModel)]="value"
          [ngClass]="{ disabled: disabled }"
          [type]="isShown ? type : 'password'"
          [disabled]="disabled"
          (ngModelChange)="onInput($event)"
        />
        <app-svg-icon
          [src]="isShown ? AppIcon.IconShow : AppIcon.IconHide"
          [ngClass]="{ blue: true, disabled: disabled }"
          (click)="disabled ? null : toggleShow(!isShown)"
        >
        </app-svg-icon>
      </span>

      <span *ngSwitchCase="InputFormat.DatePicker">
        <input
          [(ngModel)]="value"
          [ngClass]="{ disabled: disabled }"
          [type]="type"
          [disabled]="disabled"
          [placeholder]="placeholder | translate"
          [matDatepicker]="picker"
          (dateChange)="onDateChanged($event.value)"
        />
      </span>
      <div class="bg-effect"></div>
    </div>
    <span [ngClass]="{ hide: isEditMode }">
      <div *ngIf="format == InputFormat.Default" class="value">{{ value ? value : '-' }}</div>
      <div *ngIf="format == InputFormat.Password" class="value">{{ value ? (value | password) : '-' }}</div>
      <div *ngIf="format == InputFormat.DatePicker" class="value">{{ value ? (value | date) : '-' }}</div>
    </span>
  </label>

  <div *ngIf="error" class="error-message">{{ error | translate }}</div>
</div>

<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="TabType.Underlined" class="tabs-container">
    <div
      class="tab"
      [ngClass]="{ selected: selectedTab.title === tab.title, disabled: tab.isDisabled }"
      *ngFor="let tab of tabs"
      (click)="readonly ? null : selectTab(tab)"
    >
      {{ tab.title | translate }}
      <div class="bg-effect"></div>
      <div class="selected-border"></div>
    </div>
    <hr />
  </div>

  <div *ngSwitchCase="TabType.Highlight" class="button-tabs-container">
    <ng-container *ngFor="let tab of tabs; let index = index">
      <div
        class="tab"
        [ngClass]="{
          selected: selectedTab.title === tab.title,
          disabled: tab.isDisabled,
          'border-left': index === 0,
          'border-right': index === tabs.length - 1
        }"
        (click)="readonly ? null : selectTab(tab)"
      >
        {{ tab.title | translate }}
        <div class="bg-effect"></div>
      </div>
      <div *ngIf="index !== tabs.length - 1" class="separator"></div>
    </ng-container>
  </div>

  <div *ngSwitchCase="TabType.Checkmark" class="checkmark-tab-container">
    <div
      class="tab"
      [ngClass]="{ selected: selectedTab.title === tab.title || i < selectedIndex, disabled: tab.isDisabled }"
      *ngFor="let tab of tabs; index as i"
      (click)="readonly ? null : selectTab(tab)"
    >
      {{ i + 1 }}. {{ tab.title | translate }}
      <svg-icon *ngIf="i < selectedIndex" class="green" [src]="AppIcon.IconCheckLarge" />
    </div>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { DropdownOption } from '../components/dropdown/dropdown.component';
import { TaskStatus } from '../enums/task-status.enum';
import { ClientModel } from '../models/client.model';
import { ConsultantModel } from '../models/consultant.model';
import { ProjectModel } from '../models/project.model';
import { ConsultantService } from './consultant.service';
import { ProjectService } from './project.service';
import { RequestParams } from '../utils/RequestParams';
import { ClientsService } from './clients.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private consultantsService: ConsultantService,
    private clientsService: ClientsService,
    private projectsService: ProjectService,
  ) {}

  countries: DropdownOption[] = [
    new DropdownOption({ key: 'AF', value: 'Afghanistan' }),
    new DropdownOption({ key: 'AL', value: 'Albania' }),
    new DropdownOption({ key: 'DZ', value: 'Algeria' }),
    new DropdownOption({ key: 'AS', value: 'American Samoa' }),
    new DropdownOption({ key: 'AD', value: 'Andorra' }),
    new DropdownOption({ key: 'AO', value: 'Angola' }),
    new DropdownOption({ key: 'AI', value: 'Anguilla' }),
    new DropdownOption({ key: 'AQ', value: 'Antarctica' }),
    new DropdownOption({ key: 'AG', value: 'Antigua and Barbuda' }),
    new DropdownOption({ key: 'AR', value: 'Argentina' }),
    new DropdownOption({ key: 'AM', value: 'Armenia' }),
    new DropdownOption({ key: 'AW', value: 'Aruba' }),
    new DropdownOption({ key: 'AU', value: 'Australia' }),
    new DropdownOption({ key: 'AT', value: 'Austria' }),
    new DropdownOption({ key: 'AZ', value: 'Azerbaijan' }),
    new DropdownOption({ key: 'BS', value: 'Bahamas' }),
    new DropdownOption({ key: 'BH', value: 'Bahrain' }),
    new DropdownOption({ key: 'BD', value: 'Bangladesh' }),
    new DropdownOption({ key: 'BB', value: 'Barbados' }),
    new DropdownOption({ key: 'BY', value: 'Belarus' }),
    new DropdownOption({ key: 'BE', value: 'Belgium' }),
    new DropdownOption({ key: 'BZ', value: 'Belize' }),
    new DropdownOption({ key: 'BJ', value: 'Benin' }),
    new DropdownOption({ key: 'BM', value: 'Bermuda' }),
    new DropdownOption({ key: 'BT', value: 'Bhutan' }),
    new DropdownOption({ key: 'BO', value: 'Bolivia (Plurinational State of)' }),
    new DropdownOption({ key: 'BQ', value: 'Bonaire Sint Eustatius and Saba' }),
    new DropdownOption({ key: 'BA', value: 'Bosnia and Herzegovina' }),
    new DropdownOption({ key: 'BW', value: 'Botswana' }),
    new DropdownOption({ key: 'BV', value: 'Bouvet Island' }),
    new DropdownOption({ key: 'BR', value: 'Brazil' }),
    new DropdownOption({ key: 'IO', value: 'British Indian Ocean Territory' }),
    new DropdownOption({ key: 'BN', value: 'Brunei Darussalam' }),
    new DropdownOption({ key: 'BG', value: 'Bulgaria' }),
    new DropdownOption({ key: 'BF', value: 'Burkina Faso' }),
    new DropdownOption({ key: 'BI', value: 'Burundi' }),
    new DropdownOption({ key: 'CV', value: 'Cabo Verde' }),
    new DropdownOption({ key: 'KH', value: 'Cambodia' }),
    new DropdownOption({ key: 'CM', value: 'Cameroon' }),
    new DropdownOption({ key: 'CA', value: 'Canada' }),
    new DropdownOption({ key: 'KY', value: 'Cayman Islands' }),
    new DropdownOption({ key: 'CF', value: 'Central African Republic' }),
    new DropdownOption({ key: 'TD', value: 'Chad' }),
    new DropdownOption({ key: 'CL', value: 'Chile' }),
    new DropdownOption({ key: 'CN', value: 'China' }),
    new DropdownOption({ key: 'CX', value: 'Christmas Island' }),
    new DropdownOption({ key: 'CC', value: 'Cocos (Keeling) Islands' }),
    new DropdownOption({ key: 'CO', value: 'Colombia' }),
    new DropdownOption({ key: 'KM', value: 'Comoros' }),
    new DropdownOption({ key: 'CD', value: 'Democratic Republic of the Congo' }),
    new DropdownOption({ key: 'CG', value: 'Congo' }),
    new DropdownOption({ key: 'CK', value: 'Cook Islands' }),
    new DropdownOption({ key: 'CR', value: 'Costa Rica' }),
    new DropdownOption({ key: 'HR', value: 'Croatia' }),
    new DropdownOption({ key: 'CU', value: 'Cuba' }),
    new DropdownOption({ key: 'CW', value: 'Curaçao' }),
    new DropdownOption({ key: 'CY', value: 'Cyprus' }),
    new DropdownOption({ key: 'CZ', value: 'Czechia' }),
    new DropdownOption({ key: 'CI', value: "Côte d'Ivoire" }),
    new DropdownOption({ key: 'DK', value: 'Denmark' }),
    new DropdownOption({ key: 'DJ', value: 'Djibouti' }),
    new DropdownOption({ key: 'DM', value: 'Dominica' }),
    new DropdownOption({ key: 'DO', value: 'Dominican Republic' }),
    new DropdownOption({ key: 'EC', value: 'Ecuador' }),
    new DropdownOption({ key: 'EG', value: 'Egypt' }),
    new DropdownOption({ key: 'SV', value: 'El Salvador' }),
    new DropdownOption({ key: 'GQ', value: 'Equatorial Guinea' }),
    new DropdownOption({ key: 'ER', value: 'Eritrea' }),
    new DropdownOption({ key: 'EE', value: 'Estonia' }),
    new DropdownOption({ key: 'SZ', value: 'Eswatini' }),
    new DropdownOption({ key: 'ET', value: 'Ethiopia' }),
    new DropdownOption({ key: 'FK', value: 'Falkland Islands [Malvinas]' }),
    new DropdownOption({ key: 'FO', value: 'Faroe Islands' }),
    new DropdownOption({ key: 'FJ', value: 'Fiji' }),
    new DropdownOption({ key: 'FI', value: 'Finland' }),
    new DropdownOption({ key: 'FR', value: 'France' }),
    new DropdownOption({ key: 'GF', value: 'French Guiana' }),
    new DropdownOption({ key: 'PF', value: 'French Polynesia' }),
    new DropdownOption({ key: 'TF', value: 'French Southern Territories' }),
    new DropdownOption({ key: 'GA', value: 'Gabon' }),
    new DropdownOption({ key: 'GM', value: 'Gambia' }),
    new DropdownOption({ key: 'GE', value: 'Georgia' }),
    new DropdownOption({ key: 'DE', value: 'Germany' }),
    new DropdownOption({ key: 'GH', value: 'Ghana' }),
    new DropdownOption({ key: 'GI', value: 'Gibraltar' }),
    new DropdownOption({ key: 'GR', value: 'Greece' }),
    new DropdownOption({ key: 'GL', value: 'Greenland' }),
    new DropdownOption({ key: 'GD', value: 'Grenada' }),
    new DropdownOption({ key: 'GP', value: 'Guadeloupe' }),
    new DropdownOption({ key: 'GU', value: 'Guam' }),
    new DropdownOption({ key: 'GT', value: 'Guatemala' }),
    new DropdownOption({ key: 'GG', value: 'Guernsey' }),
    new DropdownOption({ key: 'GN', value: 'Guinea' }),
    new DropdownOption({ key: 'GW', value: 'Guinea-Bissau' }),
    new DropdownOption({ key: 'GY', value: 'Guyana' }),
    new DropdownOption({ key: 'HT', value: 'Haiti' }),
    new DropdownOption({ key: 'HM', value: 'Heard Island and McDonald Islands' }),
    new DropdownOption({ key: 'VA', value: 'Holy See' }),
    new DropdownOption({ key: 'HN', value: 'Honduras' }),
    new DropdownOption({ key: 'HK', value: 'Hong Kong' }),
    new DropdownOption({ key: 'HU', value: 'Hungary' }),
    new DropdownOption({ key: 'IS', value: 'Iceland' }),
    new DropdownOption({ key: 'IN', value: 'India' }),
    new DropdownOption({ key: 'ID', value: 'Indonesia' }),
    new DropdownOption({ key: 'IR', value: 'Iran' }),
    new DropdownOption({ key: 'IQ', value: 'Iraq' }),
    new DropdownOption({ key: 'IE', value: 'Ireland' }),
    new DropdownOption({ key: 'IM', value: 'Isle of Man' }),
    new DropdownOption({ key: 'IL', value: 'Israel' }),
    new DropdownOption({ key: 'IT', value: 'Italy' }),
    new DropdownOption({ key: 'JM', value: 'Jamaica' }),
    new DropdownOption({ key: 'JP', value: 'Japan' }),
    new DropdownOption({ key: 'JE', value: 'Jersey' }),
    new DropdownOption({ key: 'JO', value: 'Jordan' }),
    new DropdownOption({ key: 'KZ', value: 'Kazakhstan' }),
    new DropdownOption({ key: 'KE', value: 'Kenya' }),
    new DropdownOption({ key: 'KI', value: 'Kiribati' }),
    new DropdownOption({ key: 'KP', value: "Democratic People's Republic of Korea" }),
    new DropdownOption({ key: 'KR', value: 'Republic of Korea' }),
    new DropdownOption({ key: 'KW', value: 'Kuwait' }),
    new DropdownOption({ key: 'KG', value: 'Kyrgyzstan' }),
    new DropdownOption({ key: 'LA', value: "Lao People's Democratic Republic" }),
    new DropdownOption({ key: 'LV', value: 'Latvia' }),
    new DropdownOption({ key: 'LB', value: 'Lebanon' }),
    new DropdownOption({ key: 'LS', value: 'Lesotho' }),
    new DropdownOption({ key: 'LR', value: 'Liberia' }),
    new DropdownOption({ key: 'LY', value: 'Libya' }),
    new DropdownOption({ key: 'LI', value: 'Liechtenstein' }),
    new DropdownOption({ key: 'LT', value: 'Lithuania' }),
    new DropdownOption({ key: 'LU', value: 'Luxembourg' }),
    new DropdownOption({ key: 'MO', value: 'Macao' }),
    new DropdownOption({ key: 'MG', value: 'Madagascar' }),
    new DropdownOption({ key: 'MW', value: 'Malawi' }),
    new DropdownOption({ key: 'MY', value: 'Malaysia' }),
    new DropdownOption({ key: 'MV', value: 'Maldives' }),
    new DropdownOption({ key: 'ML', value: 'Mali' }),
    new DropdownOption({ key: 'MT', value: 'Malta' }),
    new DropdownOption({ key: 'MH', value: 'Marshall Islands' }),
    new DropdownOption({ key: 'MQ', value: 'Martinique' }),
    new DropdownOption({ key: 'MR', value: 'Mauritania' }),
    new DropdownOption({ key: 'MU', value: 'Mauritius' }),
    new DropdownOption({ key: 'YT', value: 'Mayotte' }),
    new DropdownOption({ key: 'MX', value: 'Mexico' }),
    new DropdownOption({ key: 'FM', value: 'Federated States of Micronesia' }),
    new DropdownOption({ key: 'MD', value: 'Republic of Moldova' }),
    new DropdownOption({ key: 'MC', value: 'Monaco' }),
    new DropdownOption({ key: 'MN', value: 'Mongolia' }),
    new DropdownOption({ key: 'ME', value: 'Montenegro' }),
    new DropdownOption({ key: 'MS', value: 'Montserrat' }),
    new DropdownOption({ key: 'MA', value: 'Morocco' }),
    new DropdownOption({ key: 'MZ', value: 'Mozambique' }),
    new DropdownOption({ key: 'MM', value: 'Myanmar' }),
    new DropdownOption({ key: 'NA', value: 'Namibia' }),
    new DropdownOption({ key: 'NR', value: 'Nauru' }),
    new DropdownOption({ key: 'NP', value: 'Nepal' }),
    new DropdownOption({ key: 'NL', value: 'Netherlands' }),
    new DropdownOption({ key: 'NC', value: 'New Caledonia' }),
    new DropdownOption({ key: 'NZ', value: 'New Zealand' }),
    new DropdownOption({ key: 'NI', value: 'Nicaragua' }),
    new DropdownOption({ key: 'NE', value: 'Niger' }),
    new DropdownOption({ key: 'NG', value: 'Nigeria' }),
    new DropdownOption({ key: 'NU', value: 'Niue' }),
    new DropdownOption({ key: 'NF', value: 'Norfolk Island' }),
    new DropdownOption({ key: 'MP', value: 'Northern Mariana Islands' }),
    new DropdownOption({ key: 'NO', value: 'Norway' }),
    new DropdownOption({ key: 'OM', value: 'Oman' }),
    new DropdownOption({ key: 'PK', value: 'Pakistan' }),
    new DropdownOption({ key: 'PW', value: 'Palau' }),
    new DropdownOption({ key: 'PS', value: 'Palestinef' }),
    new DropdownOption({ key: 'PA', value: 'Panama' }),
    new DropdownOption({ key: 'PG', value: 'Papua New Guinea' }),
    new DropdownOption({ key: 'PY', value: 'Paraguay' }),
    new DropdownOption({ key: 'PE', value: 'Peru' }),
    new DropdownOption({ key: 'PH', value: 'Philippines' }),
    new DropdownOption({ key: 'PN', value: 'Pitcairn' }),
    new DropdownOption({ key: 'PL', value: 'Poland' }),
    new DropdownOption({ key: 'PT', value: 'Portugal' }),
    new DropdownOption({ key: 'PR', value: 'Puerto Rico' }),
    new DropdownOption({ key: 'QA', value: 'Qatar' }),
    new DropdownOption({ key: 'MK', value: 'Republic of North Macedonia' }),
    new DropdownOption({ key: 'RO', value: 'Romania' }),
    new DropdownOption({ key: 'RU', value: 'Russian Federation' }),
    new DropdownOption({ key: 'RW', value: 'Rwanda' }),
    new DropdownOption({ key: 'RE', value: 'Réunion' }),
    new DropdownOption({ key: 'BL', value: 'Saint Barthélemy' }),
    new DropdownOption({ key: 'SH', value: 'Saint Helena Ascension and Tristan da Cunha' }),
    new DropdownOption({ key: 'KN', value: 'Saint Kitts and Nevis' }),
    new DropdownOption({ key: 'LC', value: 'Saint Lucia' }),
    new DropdownOption({ key: 'MF', value: 'Saint Martin (French part)' }),
    new DropdownOption({ key: 'PM', value: 'Saint Pierre and Miquelon' }),
    new DropdownOption({ key: 'VC', value: 'Saint Vincent and the Grenadines' }),
    new DropdownOption({ key: 'WS', value: 'Samoa' }),
    new DropdownOption({ key: 'SM', value: 'San Marino' }),
    new DropdownOption({ key: 'ST', value: 'Sao Tome and Principe' }),
    new DropdownOption({ key: 'SA', value: 'Saudi Arabia' }),
    new DropdownOption({ key: 'SN', value: 'Senegal' }),
    new DropdownOption({ key: 'RS', value: 'Serbia' }),
    new DropdownOption({ key: 'SC', value: 'Seychelles' }),
    new DropdownOption({ key: 'SL', value: 'Sierra Leone' }),
    new DropdownOption({ key: 'SG', value: 'Singapore' }),
    new DropdownOption({ key: 'SX', value: 'Sint Maarten (Dutch part)' }),
    new DropdownOption({ key: 'SK', value: 'Slovakia' }),
    new DropdownOption({ key: 'SI', value: 'Slovenia' }),
    new DropdownOption({ key: 'SB', value: 'Solomon Islands' }),
    new DropdownOption({ key: 'SO', value: 'Somalia' }),
    new DropdownOption({ key: 'ZA', value: 'South Africa' }),
    new DropdownOption({ key: 'GS', value: 'South Georgia and the South Sandwich Islands' }),
    new DropdownOption({ key: 'SS', value: 'South Sudan' }),
    new DropdownOption({ key: 'ES', value: 'Spain' }),
    new DropdownOption({ key: 'LK', value: 'Sri Lanka' }),
    new DropdownOption({ key: 'SD', value: 'Sudan' }),
    new DropdownOption({ key: 'SR', value: 'Suriname' }),
    new DropdownOption({ key: 'SJ', value: 'Svalbard and Jan Mayen' }),
    new DropdownOption({ key: 'SE', value: 'Sweden' }),
    new DropdownOption({ key: 'CH', value: 'Switzerland' }),
    new DropdownOption({ key: 'SY', value: 'Syrian Arab Republic' }),
    new DropdownOption({ key: 'TW', value: 'Taiwan' }),
    new DropdownOption({ key: 'TJ', value: 'Tajikistan' }),
    new DropdownOption({ key: 'TZ', value: 'Tanzania United Republic of' }),
    new DropdownOption({ key: 'TH', value: 'Thailand' }),
    new DropdownOption({ key: 'TL', value: 'Timor-Leste' }),
    new DropdownOption({ key: 'TG', value: 'Togo' }),
    new DropdownOption({ key: 'TK', value: 'Tokelau' }),
    new DropdownOption({ key: 'TO', value: 'Tonga' }),
    new DropdownOption({ key: 'TT', value: 'Trinidad and Tobago' }),
    new DropdownOption({ key: 'TN', value: 'Tunisia' }),
    new DropdownOption({ key: 'TR', value: 'Turkey' }),
    new DropdownOption({ key: 'TM', value: 'Turkmenistan' }),
    new DropdownOption({ key: 'TC', value: 'Turks and Caicos Islands' }),
    new DropdownOption({ key: 'TV', value: 'Tuvalu' }),
    new DropdownOption({ key: 'UG', value: 'Uganda' }),
    new DropdownOption({ key: 'UA', value: 'Ukraine' }),
    new DropdownOption({ key: 'AE', value: 'United Arab Emirates' }),
    new DropdownOption({ key: 'GB', value: 'United Kingdom of Great Britain and Northern Ireland' }),
    new DropdownOption({ key: 'UM', value: 'United States Minor Outlying Islands' }),
    new DropdownOption({ key: 'US', value: 'United States of America' }),
    new DropdownOption({ key: 'UY', value: 'Uruguay' }),
    new DropdownOption({ key: 'UZ', value: 'Uzbekistan' }),
    new DropdownOption({ key: 'VU', value: 'Vanuatu' }),
    new DropdownOption({ key: 'VE', value: 'Venezuela (Bolivarian Republic of)' }),
    new DropdownOption({ key: 'VN', value: 'Viet Nam' }),
    new DropdownOption({ key: 'VG', value: 'Virgin Islands (British)' }),
    new DropdownOption({ key: 'VI', value: 'Virgin Islands (U.S.)' }),
    new DropdownOption({ key: 'WF', value: 'Wallis and Futuna' }),
    new DropdownOption({ key: 'EH', value: 'Western Sahara' }),
    new DropdownOption({ key: 'YE', value: 'Yemen' }),
    new DropdownOption({ key: 'ZM', value: 'Zambia' }),
    new DropdownOption({ key: 'ZW', value: 'Zimbabwe' }),
    new DropdownOption({ key: 'AX', value: 'Åland Islands' }),
  ];

  taskStatus: DropdownOption[] = [
    {
      key: TaskStatus.TO_DO,
      value: 'To do',
      color: '#00C5E3',
    },
    {
      key: TaskStatus.REFINEMENT,
      value: 'Refinement',
      color: '#FE6600',
    },
    {
      key: TaskStatus.IN_PROGRESS,
      value: 'In progress',
      color: '#8800E3',
    },
    {
      key: TaskStatus.DONE,
      value: 'Done',
      color: '#00B976',
    },
  ];

  get consultants(): Observable<DropdownOption[]> {
    return this.consultantsService.getConsultantsList(new RequestParams({ fields: ['firstName', 'lastName'] })).pipe(
      map((result: ConsultantModel[]) => {
        return result.map((r) => new DropdownOption({ key: r.id, value: `${r.firstName} ${r.lastName}` }));
      }),
    );
  }

  get clients(): Observable<DropdownOption[]> {
    return this.clientsService.getAll(new RequestParams({ fields: ['name'] })).pipe(
      map((result: ClientModel[]) => {
        return result.map((r) => new DropdownOption({ key: r.id, value: `${r.name}` }));
      }),
    );
  }

  get projects(): Observable<DropdownOption[]> {
    return this.projectsService.getProjects(new RequestParams({ fields: ['name'] })).pipe(
      map((result: ProjectModel[]) => {
        return result.map((r) => new DropdownOption({ key: r.id, value: `${r.name}` }));
      }),
    );
  }
}

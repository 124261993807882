import { DocumentModel } from './document.model';

export class ClientModel {
  id?: string;
  name?: string;
  registrationNumber?: string;
  pointOfSale?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  streetAddress?: string;
  buildingDescription?: string;
  city?: string;
  country?: string;
  county?: string;
  projectCount?: number;
  taskCount?: number;
  documents?: DocumentModel[];

  constructor(clientObj: any) {
    this.id = clientObj?.id;
    this.name = clientObj?.name;
    this.registrationNumber = clientObj?.registrationNumber;
    this.pointOfSale = clientObj?.pointOfSale;
    this.contactFirstName = clientObj?.contactFirstName;
    this.contactLastName = clientObj?.contactLastName;
    this.contactEmail = clientObj?.contactEmail;
    this.contactPhone = clientObj?.contactPhone;
    this.streetAddress = clientObj?.streetAddress;
    this.buildingDescription = clientObj?.buildingDescription;
    this.city = clientObj?.city;
    this.country = clientObj?.country;
    this.county = clientObj?.county;
    this.projectCount = clientObj?.projectCount;
    this.taskCount = clientObj?.taskCount;
    this.documents = clientObj?.documents;
  }

  set all(clientObj: any) {
    this.name = clientObj?.name || this.name;
    this.registrationNumber = clientObj?.registrationNumber || this.registrationNumber;
    this.pointOfSale = clientObj?.pointOfSale || this.pointOfSale;
    this.contactFirstName = clientObj?.contactFirstName || this.contactFirstName;
    this.contactLastName = clientObj?.contactLastName || this.contactLastName;
    this.contactEmail = clientObj?.contactEmail || this.contactEmail;
    this.contactPhone = clientObj?.contactPhone || this.contactPhone;
    this.streetAddress = clientObj?.streetAddress || this.streetAddress;
    this.buildingDescription = clientObj?.buildingDescription || this.buildingDescription;
    this.city = clientObj?.city || this.city;
    this.country = clientObj?.country || this.country;
    this.county = clientObj?.county || this.county;
    this.projectCount = clientObj?.projectCount || this.projectCount;
    this.taskCount = clientObj?.taskCount || this.taskCount;
    this.documents = clientObj?.documents || this.documents;
  }
}

import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent) {
    if (!this.isInside(event.target as HTMLElement)) {
      this.clickOutside.emit();
    }
  }

  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  isInside(element: HTMLElement): boolean {
    return element === this.elementRef.nativeElement || this.elementRef.nativeElement.contains(element);
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-highlight-text',
  templateUrl: './highlight-text.component.html',
  styleUrls: ['./highlight-text.component.scss'],
})
export class HighlightTextComponent implements OnInit, OnDestroy {
  @Input() searchKey = 'search';
  @Input() textToHighlight: string = '';
  @Input() text: string | undefined = '';

  private searchSub!: Subscription | undefined;

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.searchSub = this.searchService.getSearchString(this.searchKey)?.subscribe((data) => {
      this.textToHighlight = data;
    });
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
  }
}

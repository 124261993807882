<button
  class="{{ theme }} {{ state }}"
  [ngClass]="{ 'disabled-state': disabled }"
  [disabled]="disabled"
  (click)="toggle()"
>
  <div *ngIf="dot" class="dot"></div>
  <ng-content></ng-content>
  <div class="hover-effect"></div>
</button>

<ng-container [ngSwitch]="type">
  <div
    class="datepicker-container"
    [ngClass]="{ disabled: disabled, 'without-label': !labelName }"
    *ngSwitchCase="DatepickerType.Default"
    (click)="isEditMode ? picker.open() : null"
  >
    <app-input-text
      [picker]="picker"
      [format]="InputFormat.DatePicker"
      [labelName]="labelName"
      [disabled]="disabled"
      [error]="error"
      [isEditMode]="isEditMode"
      [ngModel]="value"
      [readonly]="true"
      (dateChanged)="onDateChange($event, DateType.Default)"
      labelTheme="dark"
      inputTheme="light"
      type="text"
      placeholder="{{ 'component.datepicker.date.placeholder' | translate }}"
    />
    <app-svg-icon
      *ngIf="value && isEditMode"
      [src]="AppIcon.IconX"
      class="clear-icon"
      (click)="$event.stopPropagation(); onClearDate()"
    />
    <span [ngClass]="{ hide: !isEditMode }">
      <mat-datepicker-toggle matIconSuffix [for]="picker">
        <app-svg-icon [src]="AppIcon.IconCalendar" matDatepickerToggleIcon />
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </span>
  </div>

  <div
    class="datepicker-container range"
    [ngClass]="{ disabled: disabled, 'without-label': !labelName }"
    *ngSwitchCase="DatepickerType.Range"
  >
    <mat-datepicker-toggle matIconSuffix [for]="rangePicker">
      <app-svg-icon [src]="AppIcon.IconCalendar" matDatepickerToggleIcon />
    </mat-datepicker-toggle>
    <div class="bg-effect"></div>
    <mat-date-range-input [rangePicker]="rangePicker" (click)="rangePicker.open()">
      <input
        matStartDate
        type="text"
        [value]="rangeDates.startDate"
        (dateChange)="onDateChange($event.value, DateType.Start)"
        placeholder="{{ 'component.datepicker.range.placeholder' | translate }}"
      />
      <input
        matEndDate
        type="text"
        [value]="rangeDates.endDate"
        (dateChange)="onDateChange($event.value, DateType.End)"
      />
    </mat-date-range-input>
    <app-svg-icon *ngIf="rangeDates.endDate" [src]="AppIcon.IconX" class="clear-icon" (click)="onClearRange()" />
    <mat-date-range-picker #rangePicker></mat-date-range-picker>
  </div>
</ng-container>

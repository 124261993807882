import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[phone]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneDirective, multi: true }],
})
export class PhoneDirective implements Validator {
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    let value = control.value;
    if (!value) return null;
    let regex = new RegExp('^[0-9]{10}$');
    return !regex.test(value) ? { phone: true } : null;
  }
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertType } from '../enums/alert-type.enum';
import { ModalService } from './modal.service';

export type PostNavigationAlert = {
  type: AlertType.Success | AlertType.Error;
  title?: string;
  subtitle?: string;
};

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private history: string[] = [];

  private postNavigationAlert?: PostNavigationAlert;

  constructor(
    private router: Router,
    private location: Location,
    private modalService: ModalService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        if (this.postNavigationAlert) {
          this.showPostNavigationAlert();
        }
      }
    });
  }

  navigate(path: string, queryParams?: Object, postNavigationAlert?: PostNavigationAlert) {
    this.postNavigationAlert = postNavigationAlert;
    if (queryParams) this.router.navigate([path], { queryParams: queryParams });
    else this.router.navigateByUrl(path);
  }

  back(path?: string, postNavigationAlert?: PostNavigationAlert): void {
    this.postNavigationAlert = postNavigationAlert;
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/' + path);
    }
  }

  private showPostNavigationAlert() {
    if (!this.postNavigationAlert) {
      return;
    }

    if (this.postNavigationAlert.type === AlertType.Success) {
      this.modalService.openSuccess({ subtitle: this.postNavigationAlert.subtitle });
    } else {
      this.modalService.openError({
        title: this.postNavigationAlert.title,
        subtitle: this.postNavigationAlert.subtitle,
      });
    }

    this.postNavigationAlert = undefined;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, Observable, concatMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientModel } from '../models/client.model';
import { ResponseError } from '../models/response-error.model';
import { RequestParams } from '../utils/RequestParams';
import { DocumentModel } from '../models/document.model';
import { ResponseModel } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private http: HttpClient) {}

  getAll(params: RequestParams): Observable<ClientModel[]> {
    return this.http
      .get(`${environment.host}clients`, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: any) => {
          let clients: ClientModel[] = response.data.map((data: any) => new ClientModel(data));
          return clients;
        }),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  getOne(id: string, params: RequestParams): Observable<ClientModel> {
    return this.http
      .get(`${environment.host}clients/${id}`, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: any) => {
          let client = new ClientModel(response.data);
          return client;
        }),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  create(client: ClientModel): Observable<ClientModel> {
    return this.http.post(`${environment.host}clients`, client).pipe(
      map((response: any) => {
        let client = new ClientModel(response.data);
        return client;
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  update(id: string = '', client: ClientModel, params: RequestParams): Observable<ClientModel> {
    return this.http
      .patch(`${environment.host}clients/${id}`, client, {
        params: params.getParamsObject(),
      })
      .pipe(
        map((response: any) => {
          let client = new ClientModel(response.data);
          return client;
        }),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  delete(id: string) {
    return this.http.delete(`${environment.host}clients/${id}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }

  uploadFile(clientId: string, fileUrl: string, fileName: string): Observable<DocumentModel> {
    return this.http
      .post<ResponseModel<DocumentModel>>(`${environment.host}clients/${clientId}/documents`, {
        fileUrl: fileUrl,
        name: fileName,
      })
      .pipe(
        map((response: ResponseModel<DocumentModel>) => {
          return new DocumentModel(response.data);
        }),
        catchError((error) => {
          throw new ResponseError(error);
        }),
      );
  }

  deleteFile(clientId: string, documentId: string): Observable<ResponseModel<void>> {
    return this.http.delete<ResponseModel<void>>(`${environment.host}clients/${clientId}/documents/${documentId}`).pipe(
      catchError((error) => {
        throw new ResponseError(error);
      }),
    );
  }
}
